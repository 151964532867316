import { VFC, useState, useMemo, useCallback, useEffect } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { capitalize, isEmpty } from "lodash";
import { Image, Text } from "theme-ui";

import { Filters, getHasuaExpFromFilters, modelFilterDefinitions } from "src/components/filter";
import { CreateViewModal } from "src/components/filter/create-view";
import { Views } from "src/components/filter/views";
import { LabelsCell } from "src/components/labels/labels-cell";
import { Page } from "src/components/layout";
import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import { Permission } from "src/components/permission";
import { SyncsCell } from "src/components/syncs/syncs-cell";
import { PermissionProvider } from "src/contexts/permission-context";
import {
  ResourcePermissionGrant,
  SegmentsBoolExp,
  SegmentsOrderBy,
  useDeleteModelsMutation,
  useModelsQuery,
} from "src/graphql";
import useQueryState from "src/hooks/use-query-state";
import * as analytics from "src/lib/analytics";
import { Fade } from "src/ui/animations/fade";
import { Row, Column, Box } from "src/ui/box";
import { Button, DropdownButton } from "src/ui/button";
import { Heading } from "src/ui/heading";
import { SearchInput } from "src/ui/input";
import { Table, Pagination, useTableConfig, TableColumn } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { useFiltering } from "src/ui/table/use-filtering";
import { useRowSelect } from "src/ui/table/use-row-select";
import { useDestinations } from "src/utils/destinations";
import { QueryType } from "src/utils/models";
import { useNavigate } from "src/utils/navigate";
import { abbreviateNumber } from "src/utils/numbers";
import { useSources } from "src/utils/sources";
import { openUrl } from "src/utils/urls";

export const Models: VFC = () => {
  const { appEnableResourceTags, appEnablePrimaryResourceFiltering } = useFlags();
  const navigate = useNavigate();
  const [search, setSearch] = useQueryState("search");
  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const { mutateAsync: bulkDelete, isLoading: loadingBulkDelete } = useDeleteModelsMutation();
  const [loading, setLoading] = useState<boolean>(true);
  const [createViewModalOpen, setCreateViewModalOpen] = useState(false);

  const { limit, offset, orderBy, page, setPage, onSort } = useTableConfig<SegmentsOrderBy>({
    defaultSortKey: "updated_at",
  });

  const {
    state: { creatingView, filters, selectedView, viewNotSaved, views, updatingView },
    actions: { createView, deleteView, resetViewFilters, selectView, updateCurrentView, updateFilters },
  } = useFiltering({ viewKey: "model" });

  const hasuraFilters = useMemo(() => {
    const hasuraFilters: SegmentsBoolExp = {
      _and: [
        { is_schema: { _eq: false } },
        { query_type: { _neq: "visual" } },
        getHasuaExpFromFilters(modelFilterDefinitions, appEnablePrimaryResourceFiltering ? filters : []),
      ].filter((object) => !isEmpty(object)),
    };

    if (search) {
      hasuraFilters._and!.push({ name: { _ilike: `%${search}%` } });
    }

    return hasuraFilters;
  }, [appEnablePrimaryResourceFiltering, filters, search]);

  // used for filters
  const { data: allModels } = useModelsQuery({ offset: 0, limit: 1000 });

  const {
    data,
    error,
    isLoading: initialLoading,
    isRefetching,
  } = useModelsQuery(
    {
      offset,
      limit,
      filters: hasuraFilters,
      orderBy,
    },
    {
      refetchInterval: 3000,
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const models = data?.segments;
  const modelsCount = data?.segments_aggregate?.aggregate?.count ?? 0;

  const {
    data: { definitions: destinationDefinitions },
  } = useDestinations();

  const { data: sources } = useSources();

  const columns = useMemo(
    (): TableColumn[] => [
      {
        name: "Name",
        sortDirection: orderBy?.name,
        onClick: () => onSort("name"),
        cell: ({ name, connection: { id }, tags }) => {
          const source = sources?.find((source) => source.id === id);

          return (
            <Row sx={{ alignItems: "center", mt: 1 }}>
              <Image
                alt={source?.definition?.name}
                src={source?.definition?.icon}
                sx={{ width: "20px", maxHeight: "100%", objectFit: "contain", mr: 2, flexShrink: 0 }}
              />
              <Text sx={{ fontWeight: "semi" }}>{name}</Text>
              {appEnableResourceTags && <LabelsCell labels={tags} />}
            </Row>
          );
        },
      },
      {
        name: "Size",
        key: "query_runs.[0].size",
        max: "max-content",
        cell: (size) => (size ? <Text>{abbreviateNumber(size)}</Text> : <Text sx={{ color: "base.4" }}>--</Text>),
      },
      {
        name: "Syncs",
        sortDirection: orderBy?.syncs_aggregate?.count,
        onClick: () => onSort("syncs_aggregate.count"),
        max: "max-content",
        min: "232px",
        disabled: ({ syncs }) => Boolean(syncs?.length),
        cell: ({ syncs }) => {
          return <SyncsCell definitions={destinationDefinitions ?? []} syncs={syncs} />;
        },
      },
      {
        name: "Type",
        sortDirection: orderBy?.query_type,
        onClick: () => onSort("query_type"),
        max: "max-content",
        cell: ({ query_type, custom_query }) => (
          <Text sx={{ fontWeight: "semi", color: "base.6" }}>
            {query_type === QueryType.Table
              ? "Table"
              : query_type === QueryType.Dbt
              ? "dbt"
              : query_type === QueryType.DbtModel
              ? "dbt Model"
              : query_type === QueryType.Custom
              ? custom_query?.["type"]
                ? capitalize(custom_query["type"])
                : "Custom"
              : "SQL"}
          </Text>
        ),
      },
      {
        ...LastUpdatedColumn,
        sortDirection: orderBy?.updated_at,
        onClick: () => onSort("updated_at"),
      },
    ],
    [sources, destinationDefinitions, orderBy],
  );

  const onRowClick = useCallback(({ id }, event) => openUrl(`/models/${id}`, navigate, event), [navigate]);

  const placeholder = useMemo(
    () => ({
      title: "No Models",
      body: search ? "" : "Add a model to get started",
      error: "Models failed to load, please try again.",
    }),
    [search],
  );

  useEffect(() => {
    setPage(0);
  }, [hasuraFilters]);

  useEffect(() => {
    onRowSelect([]);
  }, [page]);

  useEffect(() => {
    setLoading(true);
  }, [limit, offset, search, orderBy, hasuraFilters]);

  useEffect(() => {
    if (!isRefetching) {
      setLoading(false);
    }
  }, [isRefetching, models]);

  return (
    <>
      <PermissionProvider permissions={[{ resource: "segment", grants: [ResourcePermissionGrant.Write] }]}>
        <Page crumbs={[{ label: "Models" }]} size="full">
          <Column sx={{ mb: 3, width: "100%" }}>
            <Row sx={{ alignItems: "center", justifyContent: "space-between", mb: 8 }}>
              <Row sx={{ alignItems: "center " }}>
                <Heading sx={{ mr: 2 }}>Models</Heading>
                {appEnablePrimaryResourceFiltering && (
                  <>
                    <Views value={selectedView} views={views} onChange={selectView} onDelete={deleteView} />
                    {viewNotSaved &&
                      (selectedView === "Default view" ? (
                        <Button
                          sx={{ ml: 2 }}
                          variant="purple"
                          onClick={() => {
                            setCreateViewModalOpen(true);
                          }}
                        >
                          Save as
                        </Button>
                      ) : (
                        <DropdownButton
                          loading={updatingView}
                          options={[
                            {
                              label: "Save as",
                              onClick: () => {
                                setCreateViewModalOpen(true);
                              },
                            },
                            {
                              label: "Reset changes",
                              onClick: () => {
                                resetViewFilters();
                              },
                            },
                          ]}
                          sx={{ ml: 2 }}
                          onClick={updateCurrentView}
                        >
                          Save
                        </DropdownButton>
                      ))}
                  </>
                )}
              </Row>
              <Permission>
                <Button
                  onClick={() => {
                    analytics.track("Add Model Clicked");
                    navigate(`/models/new`);
                  }}
                >
                  Add model
                </Button>
              </Permission>
            </Row>
            <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
                <SearchInput placeholder="Search models by name..." value={search ?? ""} onChange={setSearch} />
                {appEnablePrimaryResourceFiltering && (
                  <Filters
                    data={allModels?.segments ?? []}
                    filterDefinitions={modelFilterDefinitions}
                    filters={filters}
                    resourceType="segment"
                    sx={{ ml: 2 }}
                    onChange={updateFilters}
                  />
                )}
              </Box>

              <Fade hidden={!selectedRows.length} sx={{ display: "flex", alignItems: "center" }}>
                <Button sx={{ mr: 4 }} variant="secondary" onClick={() => onRowSelect([])}>
                  Cancel
                </Button>
                <Button variant="soft" onClick={() => setConfirmingDelete(true)}>
                  Delete
                </Button>
              </Fade>
            </Row>
          </Column>

          <Table
            columns={columns}
            data={models}
            error={Boolean(error)}
            loading={initialLoading || loading}
            placeholder={placeholder}
            selectedRows={selectedRows}
            onRowClick={onRowClick}
            onSelect={onRowSelect}
          />

          <Pagination count={modelsCount} label="models" page={page} rowsPerPage={limit} setPage={setPage} />
        </Page>
      </PermissionProvider>

      <BulkDeleteConfirmationModal
        count={selectedRows.length}
        isOpen={confirmingDelete}
        label="model"
        loading={loadingBulkDelete}
        onClose={() => setConfirmingDelete(false)}
        onDelete={async () => {
          await bulkDelete({ ids: selectedRows.map(String) });
          onRowSelect([]);
        }}
      />

      <CreateViewModal
        isOpen={createViewModalOpen}
        loading={creatingView}
        onClose={() => setCreateViewModalOpen(false)}
        onSave={createView}
      />
    </>
  );
};
