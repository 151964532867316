import { FC, useState } from "react";

import { isEqual } from "lodash";
import { useToasts } from "react-toast-notifications";
import { Grid, Text } from "theme-ui";

import { Row } from "src/ui/box";
import { Heading } from "src/ui/heading";
import { Link } from "src/ui/link";
import { Message } from "src/ui/message";
import { UseSourceResult } from "src/utils/sources";

import { useUpdateSyncMutation } from "../../graphql";
import { Button } from "../../ui/button";
import { Checkbox } from "../../ui/checkbox";
import { Field } from "../../ui/field";
import { Help } from "../help";
import { Permission } from "../permission";

type Source = NonNullable<UseSourceResult["data"]>;

type WarehouseSyncLogsConfig = {
  config: {
    tablesToWrite: {
      changelog: boolean;
      snapshot: boolean;
      syncRuns: boolean;
    };
  };
  userId: string | undefined;
  id: string | undefined;
  source: Source | undefined;
};

export const WarehouseSyncLogs: FC<Readonly<WarehouseSyncLogsConfig>> = ({ id, userId, config, source }) => {
  const { addToast } = useToasts();
  const [warehouseSyncLogsConfig, setWarehouseSyncLogsConfig] = useState<{
    tablesToWrite: {
      changelog: boolean;
      snapshot: boolean;
      syncRuns: boolean;
    };
  }>(config);

  const [updatingWarehouseSyncLogsConfig, setUpdatingWarehouseSyncLogsConfig] = useState<boolean>(false);

  const { mutateAsync: updateSync } = useUpdateSyncMutation();

  const updateWarehouseSyncLogsConfig = async () => {
    if (!id) {
      return;
    }

    setUpdatingWarehouseSyncLogsConfig(true);
    await updateSync({
      id,
      object: {
        updated_by: userId,
        warehouse_history_config: warehouseSyncLogsConfig,
      },
    });
    setUpdatingWarehouseSyncLogsConfig(false);
    addToast(`Sync configuration updated!`, {
      appearance: "success",
    });
  };

  const dirty = !isEqual(warehouseSyncLogsConfig?.tablesToWrite, config?.tablesToWrite);

  return (
    <Row sx={{ alignItems: "start", width: "100%", justifyContent: "space-between" }}>
      <Grid gap={6} sx={{ maxWidth: "800px" }}>
        <Heading>Warehouse Sync Logs</Heading>

        <Text>
          <Link to={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs`}>Warehouse Sync Logs</Link> writes information
          on the status of each row of your sync back into your warehouse. This is useful for analyzing sync failures and
          changes in your data over time.
          <br />
          <br />
          Specific use cases include{" "}
          <Link to={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs/#get-the-most-common-sync-error`}>
            analyzing common syncs errors
          </Link>
          ,{" "}
          <Link to={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs/#track-when-users-entered-and-exited-a-model`}>
            visualizing when users enter and exit audiences
          </Link>
          , and{" "}
          <Link to={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs/#get-the-current-rows-in-all-models`}>
            tracking the status of rows across syncs
          </Link>
          .
        </Text>
        {!source?.plan_in_warehouse && (
          <Message variant="warning">
            This feature can only be enabled if the source is using Warehouse Planning. You can enable Warehouse Planning for
            this source <Link to={`/sources/${source?.id}`}>here</Link>.
          </Message>
        )}
        {source?.plan_in_warehouse && (
          <Field
            description="Choose what you would like to track in the warehouse. The data will be written after each sync run completes."
            label="Tables"
            size="large"
          >
            <Grid gap={2}>
              <Checkbox
                description="Tracks the latest status of each row in the sync. This is useful for understanding the overall health of your syncs."
                label="Snapshot"
                size="large"
                value={warehouseSyncLogsConfig?.tablesToWrite?.snapshot}
                onChange={(value: boolean) => {
                  setWarehouseSyncLogsConfig({
                    tablesToWrite: {
                      snapshot: value,
                      changelog: warehouseSyncLogsConfig?.tablesToWrite?.changelog,
                      syncRuns: warehouseSyncLogsConfig?.tablesToWrite?.syncRuns,
                    },
                  });
                }}
              />
              <Checkbox
                description="Tracks every operation performed by Hightouch. Includes the result of the operation, as well as any error messages from syncing."
                label="Changelog"
                size="large"
                value={warehouseSyncLogsConfig?.tablesToWrite?.changelog}
                onChange={(value: boolean) => {
                  setWarehouseSyncLogsConfig({
                    tablesToWrite: {
                      snapshot: warehouseSyncLogsConfig?.tablesToWrite?.snapshot,
                      changelog: value,
                      syncRuns: warehouseSyncLogsConfig?.tablesToWrite?.syncRuns,
                    },
                  });
                }}
              />
              <Checkbox
                description={
                  "Contains a log of all the sync runs. The changelog and snapshot tables can be JOINed to this table for more information on when the sync occurred and how it was configured."
                }
                label="Sync Runs"
                size="large"
                value={warehouseSyncLogsConfig?.tablesToWrite?.syncRuns}
                onChange={(value: boolean) => {
                  setWarehouseSyncLogsConfig({
                    tablesToWrite: {
                      snapshot: warehouseSyncLogsConfig?.tablesToWrite?.snapshot,
                      changelog: warehouseSyncLogsConfig?.tablesToWrite?.changelog,
                      syncRuns: value,
                    },
                  });
                }}
              />
            </Grid>
          </Field>
        )}
      </Grid>
      {source?.plan_in_warehouse && (
        <Grid gap={2} sx={{ width: "208px", position: "sticky", top: 24 }}>
          <Permission>
            <Button
              disabled={!dirty}
              loading={updatingWarehouseSyncLogsConfig}
              sx={{ width: "100%" }}
              onClick={() => updateWarehouseSyncLogsConfig()}
            >
              Save
            </Button>
          </Permission>
          <Help docs={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-sync-logs`} label={"HI"} sx={{ mt: 4 }} />
        </Grid>
      )}
    </Row>
  );
};
