import { createContext, useContext, FC } from "react";

import { groupBy } from "lodash";

import { useUser } from "src/contexts/user-context";
import {
  DestinationDefinitionFragment as DestinationDefinition,
  SourceDefinitionFragment as SourceDefinition,
  SyncQuery,
  MakeOptional,
} from "src/graphql";
import { QueryType, useModelRun, useUpdateQuery } from "src/utils/models";

export type ColumnOption = {
  label: string;
  value: string | Record<string, unknown>;
  type: string;
  options?: ColumnOption[];
};

export type FormkitSync = SyncQuery["syncs"][0];

export type FormkitModel = MakeOptional<
  Pick<
    NonNullable<FormkitSync["segment"]>,
    | "columns"
    | "syncable_columns"
    | "connection"
    | "custom_query"
    | "id"
    | "name"
    | "parent"
    | "primary_key"
    | "query_dbt_model_id"
    | "query_looker_look_id"
    | "query_raw_sql"
    | "query_table_name"
    | "query_type"
    | "visual_query_filter"
  >,
  | "custom_query"
  | "parent"
  | "query_dbt_model_id"
  | "query_looker_look_id"
  | "query_raw_sql"
  | "query_table_name"
  | "visual_query_filter"
>;

export type FormkitDestination = NonNullable<FormkitSync["destination"]>;

export type FormkitContextType = {
  destination: FormkitDestination | undefined;
  destinationDefinition: DestinationDefinition | undefined;
  sourceDefinition: SourceDefinition | undefined;
  model: FormkitModel | undefined;
  sync: FormkitSync | undefined;
  reloadModel: () => void;
  reloadRows: () => void;
  loadingModel: boolean;
  loadingRows: boolean;
  columns: ColumnOption[];
  rows: any;
  slug: string | undefined;
  validate: any;
  sourceId: string | undefined;
  workspaceId: string;
  isSetup: boolean;
};

export const FormkitContext = createContext<FormkitContextType>({} as FormkitContextType);

export const useFormkitContext = () => useContext<FormkitContextType>(FormkitContext);

type FormkitProviderProps = {
  model?: FormkitModel;
  destination?: FormkitDestination;
  sync?: FormkitSync;
  destinationDefinition?: DestinationDefinition;
  sourceDefinition: SourceDefinition | undefined;
  validate: any;
  sourceId?: string;
  /**Used within formkit to determine how to render secret fields */
  isSetup?: boolean;
};

const getColumnValue = (column: any) => {
  if (column.column_reference) {
    if (column.column_reference.type === "raw") {
      return column.column_reference.name;
    } else {
      return column.column_reference;
    }
  }
  return column.name;
};

const mapColumn = (column) => {
  const label = column.alias || column.name;
  const columnValue = getColumnValue(column);
  const option: ColumnOption = {
    value: columnValue,
    label,
    type: column.type,
  };
  return option;
};

const mapColumns = (columns) => {
  if (columns?.length) {
    const groups = groupBy(columns, "model_name");
    const modelNames = Object.keys(groups);
    if (modelNames.length) {
      return modelNames.map((label) => ({ label, options: groups[label]?.map(mapColumn) ?? [] }));
    }
    return columns.map(mapColumn);
  }
  return [];
};

export const FormkitProvider: FC<Readonly<FormkitProviderProps>> = ({
  children,
  model,
  destination,
  destinationDefinition,
  sourceDefinition,
  sync,
  validate,
  sourceId,
  isSetup,
}) => {
  const columns = mapColumns(model?.syncable_columns);
  const { workspace } = useUser();

  const update = useUpdateQuery({ logUpdate: false });

  const {
    rows,
    runQuery,
    getSchema,
    schemaLoading,
    loading: rowsLoading,
  } = useModelRun(model?.query_type as QueryType, model?.columns, {
    modelId: model?.id,
    variables: {
      sourceId: model?.connection?.id,
      dbtModel: { id: model?.query_dbt_model_id },
      lookerLook: { id: model?.query_looker_look_id },
      parentModelId: model?.parent?.id,
      sql: model?.query_raw_sql,
      conditions: model?.visual_query_filter?.conditions,
      table: model?.query_table_name,
      customQuery: model?.custom_query,
    },
    onCompleted: async ({ columns }, error) => {
      if (columns && !error) {
        await update({ model, columns });
      }
    },
  });

  const columnsLoading = sourceDefinition?.supportsResultSchema ? schemaLoading : rowsLoading;

  const reloadRows = () => {
    runQuery(true);
  };

  const reloadColumns = () => {
    if (sourceDefinition?.supportsResultSchema) {
      getSchema();
    } else {
      reloadRows();
    }
  };

  return (
    <FormkitContext.Provider
      value={{
        isSetup: Boolean(isSetup),
        model,
        destination,
        validate,
        sync: sync,
        destinationDefinition,
        sourceDefinition,
        columns,
        loadingModel: columnsLoading,
        loadingRows: rowsLoading,
        reloadModel: reloadColumns,
        reloadRows,
        rows,
        slug: destination?.type,
        sourceId,
        workspaceId: String(workspace?.id),
      }}
    >
      {children}
    </FormkitContext.Provider>
  );
};
