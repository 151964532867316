import { useMemo, useState, VFC } from "react";

import { format, formatDuration, intervalToDuration } from "date-fns";
import { useToasts } from "react-toast-notifications";
import { Text, Grid } from "theme-ui";

import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import { InviteFormModal } from "src/components/modals/invite-modal";
import { Permission } from "src/components/permission";
import { Settings } from "src/components/settings";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  ResourcePermissionGrant,
  useApproveMembershipMutation,
  useCancelInviteMutation,
  useWorkspaceQuery,
  useMembershipRequestsByWorkspaceQuery,
  usePendingInvitesQuery,
  useRejectMembershipMutation,
  useSendInviteMutation,
  useUpdateUserWorkspaceRoleMutation,
  useDeleteMembersMutation,
  MembershipsOrderBy,
} from "src/graphql";
import { Fade } from "src/ui/animations";
import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { PersonAddIcon, WarningIcon } from "src/ui/icons";
import { SearchInput } from "src/ui/input";
import { PageSpinner } from "src/ui/loading";
import { Menu } from "src/ui/menu";
import { NewSelect } from "src/ui/new-select";
import { Pagination, Table, TableColumn, useTableConfig } from "src/ui/table";
import { useRowSelect } from "src/ui/table/use-row-select";

export const RoleSelector = ({ userId, roleId }) => {
  const { workspace, user } = useUser();

  const { mutateAsync: updateRole, isLoading: loading } = useUpdateUserWorkspaceRoleMutation();

  const roleOptions = workspace?.roles?.map(({ id, name }) => {
    return { label: name, value: id };
  });

  return (
    <NewSelect
      disabled={userId === user?.id}
      loading={loading}
      options={roleOptions}
      placeholder="Role"
      strategy="fixed"
      value={roleId}
      onChange={(roleId) => {
        updateRole({
          workspaceId: workspace?.id,
          userId,
          roleId,
        });
      }}
    />
  );
};

const placeholder = {
  title: "No members",
  error: "Members failed to load, please try again.",
};

export const Members = () => {
  const { user, workspace } = useUser();

  const [inviting, setInviting] = useState<boolean>(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");

  const { limit, offset, orderBy, page, setPage, onSort } = useTableConfig<MembershipsOrderBy>({
    defaultSortKey: "user.name",
    limit: 25,
  });

  const {
    data: workspaceData,
    isLoading: loadingWorkspace,
    isRefetching,
  } = useWorkspaceQuery(
    {
      limit,
      offset,
      search: search ? `%${search}%` : undefined,
      orderBy,
      workspaceId: workspace?.id,
    },
    {
      keepPreviousData: true,
    },
  );

  const { mutateAsync: deleteMembers, isLoading: loadingDeleteMembers } = useDeleteMembersMutation();

  const memberships = workspaceData?.workspaces_by_pk?.memberships;
  const membershipCount = workspaceData?.memberships_aggregate?.aggregate?.count ?? 0;

  // Extract users and inject role_id into each row.
  const users = useMemo(
    () =>
      memberships?.map((membership) => ({
        ...membership.user,
        role_id: membership.role?.id,
      })),
    [memberships],
  );

  const currentUserSelected = selectedRows.includes(String(user?.id));

  const columns: TableColumn[] = [
    {
      name: "Name",
      key: "name",
      sortDirection: orderBy?.user?.name,
      onClick: () => onSort("user.name"),
    },
    {
      name: "Email address",
      key: "email",
      sortDirection: orderBy?.user?.email,
      onClick: () => onSort("user.email"),
    },
    {
      name: "Role",
      sortDirection: orderBy?.role?.name,
      onClick: () => onSort("role.name"),
      cell: ({ role_id: roleId, id: userId }) => (
        <Permission fallback={workspace?.roles?.find((role) => role.id === roleId)?.name}>
          <RoleSelector roleId={roleId} userId={userId} />
        </Permission>
      ),
    },
    {
      name: "Member since",
      key: "created_at",
      sortDirection: orderBy?.user?.created_at,
      onClick: () => onSort("user.created_at"),
      cell: (timestamp) => format(new Date(timestamp), "MMMM do y"),
    },
  ];

  if (loadingWorkspace) {
    return <PageSpinner />;
  }

  return (
    <PermissionProvider permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Write] }]}>
      <Settings route="members">
        <PendingInvites defaultWorkspaceRoleId={workspace?.default_role_id} workspaceId={workspace?.id} />

        <AwaitingApproval workspaceId={workspace?.id} />

        <Row sx={{ mb: 8, justifyContent: "space-between", alignItems: "center" }}>
          <Text sx={{ fontSize: 3, fontWeight: "semi" }}>Members</Text>
          <Permission>
            <Button iconBefore={<PersonAddIcon color="white" size={16} />} onClick={() => setInviting(true)}>
              Invite new people
            </Button>
          </Permission>
        </Row>
        <Row sx={{ alignItems: "center", mb: 3, justifyContent: "space-between" }}>
          <SearchInput placeholder="Search by name or email..." value={search} onChange={setSearch} />
          <Fade hidden={!selectedRows.length} sx={{ display: "flex", alignItems: "center" }}>
            <Button sx={{ mr: 4 }} variant="secondary" onClick={() => onRowSelect([])}>
              Cancel
            </Button>
            <Button variant="soft" onClick={() => setConfirmingDelete(true)}>
              Delete
            </Button>
          </Fade>
        </Row>
        <Table
          columns={columns}
          data={users}
          loading={isRefetching}
          placeholder={placeholder}
          rowHeight={55}
          selectedRows={selectedRows}
          onSelect={onRowSelect}
        />
        <Pagination count={membershipCount} label="members" page={page} rowsPerPage={limit} setPage={setPage} />
      </Settings>

      <InviteFormModal close={() => setInviting(false)} name={workspace?.name ?? ""} open={inviting} />
      <BulkDeleteConfirmationModal
        content={currentUserSelected ? "You can not remove yourself from a workspace. Please update your selection." : null}
        count={selectedRows.length}
        disabled={currentUserSelected}
        isOpen={confirmingDelete}
        label="member"
        loading={loadingDeleteMembers}
        onClose={() => setConfirmingDelete(false)}
        onDelete={async () => {
          await deleteMembers({ workspaceId: workspace?.id, userIds: selectedRows.map(String) });
          onRowSelect([]);
        }}
      />
    </PermissionProvider>
  );
};

const AwaitingApproval = ({ workspaceId }) => {
  const { data } = useMembershipRequestsByWorkspaceQuery({
    workspaceId,
  });

  const awaitingApprovals = data?.membership_requests;

  if (!awaitingApprovals?.length) {
    return null;
  }

  return (
    <Column sx={{ mb: 12 }}>
      <Text sx={{ fontSize: 2, fontWeight: "bold", mb: 3 }}>
        {awaitingApprovals?.length} user{awaitingApprovals?.length > 1 ? "s" : ""} awaiting approval
      </Text>
      <Grid gap={3}>
        {awaitingApprovals?.map(({ user: { name, email, id } }) => (
          <MembershipRequest key={id} email={email} name={name} userId={id} workspaceId={workspaceId} />
        ))}
      </Grid>
    </Column>
  );
};

const MembershipRequest: VFC<{ name: string; email: string; workspaceId: string; userId: string }> = ({
  workspaceId,
  userId,
  name,
  email,
}) => {
  const { addToast } = useToasts();
  const { mutate: rejectRequest } = useRejectMembershipMutation();
  const { mutate: approveRequest } = useApproveMembershipMutation();

  return (
    <Row
      sx={{
        p: 3,
        borderRadius: 2,
        border: "small",
        borderColor: "secondaries.2",
        justifyContent: "space-between",
      }}
    >
      <Row sx={{ alignItems: "center" }}>
        <PersonAddIcon size={16} />
        <Text sx={{ fontWeight: "semi", ml: 2 }}>{name}</Text>
        <Text sx={{ ml: 2 }}>{email}</Text>
      </Row>
      <Row sx={{ alignItems: "center" }}>
        <Menu
          options={[
            {
              label: "Approve request",
              onClick: async () => {
                await approveRequest({
                  userId: String(userId),
                });
                addToast(`Workspace access for ${name} approved!`, {
                  appearance: "success",
                });
              },
            },
            {
              label: "Ignore",
              onClick: () =>
                rejectRequest({
                  workspaceId: String(workspaceId),
                  userId: String(userId),
                }),
            },
          ]}
        />
      </Row>
    </Row>
  );
};

const PendingInvites = ({ workspaceId, defaultWorkspaceRoleId }) => {
  const { data: pendingInvitesData } = usePendingInvitesQuery(
    {
      workspaceId,
    },
    {
      refetchInterval: 3000,
    },
  );

  const pendingInvites = pendingInvitesData?.outbound_user_invites;

  if (!pendingInvites?.length) {
    return null;
  }

  return (
    <Column sx={{ mb: 12 }}>
      <Text sx={{ fontSize: 2, fontWeight: "bold", mb: 3 }}>
        {pendingInvites?.length} pending invite{pendingInvites?.length > 1 ? "s" : ""}
      </Text>
      <Grid gap={3}>
        {pendingInvites?.map(({ recipient_email, expires_at, role_id }) => (
          <PendingInvite
            key={recipient_email}
            defaultWorkspaceRoleId={defaultWorkspaceRoleId}
            expiration={expires_at}
            recipient={recipient_email}
            roleID={role_id}
            workspaceId={workspaceId}
          />
        ))}
      </Grid>
    </Column>
  );
};

const PendingInvite: VFC<{
  recipient: string;
  expiration: string;
  workspaceId: string;
  roleID?: number | null;
  defaultWorkspaceRoleId: number;
}> = ({ workspaceId, recipient, expiration, roleID, defaultWorkspaceRoleId }) => {
  const { addToast } = useToasts();

  const { mutate: cancelInvite } = useCancelInviteMutation();
  const { mutateAsync: sendInvite } = useSendInviteMutation();

  const expired = new Date(expiration) < new Date();

  return (
    <Row
      sx={{
        p: 3,
        borderRadius: 2,
        border: "small",
        borderColor: "secondaries.2",
        justifyContent: "space-between",
      }}
    >
      <Row sx={{ alignItems: "center" }}>
        <PersonAddIcon size={16} />
        <Text sx={{ fontWeight: "semi", ml: 2 }}>{recipient}</Text>
      </Row>
      <Row sx={{ alignItems: "center" }}>
        {expired ? (
          <>
            <WarningIcon color="yellow" size={16} />
            <Text sx={{ mr: 4, ml: 2 }}>Expired</Text>
          </>
        ) : (
          <Text sx={{ mr: 4 }}>
            Expires in{" "}
            {formatDuration(intervalToDuration({ start: new Date(), end: new Date(expiration) }), {
              format: ["days"],
            }) || "<1 day"}
          </Text>
        )}
        <Menu
          options={[
            {
              label: "Resend invite",
              onClick: async () => {
                if (!roleID) {
                  return;
                }

                await sendInvite({
                  recipientEmail: recipient,
                  recipientRoleID: roleID || defaultWorkspaceRoleId,
                });
                addToast(`Invite for ${recipient} resent!`, {
                  appearance: "success",
                });
              },
            },
            {
              label: "Cancel invite",
              onClick: () =>
                cancelInvite({
                  recipientEmail: recipient,
                  workspaceId,
                }),
            },
          ]}
        />
      </Row>
    </Row>
  );
};
