import { Code } from "src/ui/code";
import { Field, FieldError } from "src/ui/field";
import { Input } from "src/ui/input";
import { Select } from "src/ui/select";

import { useCloudCredentialQuery } from "../../../graphql";
import { SelectCredential } from "../../credentials";

export const BigQueryForm = ({ config, setConfig, credentialId, setCredentialId, error }) => {
  // Format location options like Google's own dropdown selector: "United States (us)"
  const formattedLocationOptions = LOCATION_OPTIONS.map((a) => ({ label: `${a.description} (${a.name})`, value: a.name }));
  const { data: credentialData } = useCloudCredentialQuery({ id: credentialId });
  const credentialConfig = credentialData?.cloud_credentials_by_pk?.stripped_config;

  return (
    <>
      <Field description="A BigQuery project ID, e.g some-project-word-12305623531" label="Project">
        <Input value={config?.project || ""} onChange={(value) => setConfig({ ...config, project: value })} />
      </Field>

      <Field
        description={`Typically a multi-region identifier like "US" or "EU" but can also be a specific data set location like australia-southeast1.`}
        label="Dataset Location"
      >
        <Select
          options={formattedLocationOptions}
          placeholder="Select a location..."
          value={formattedLocationOptions?.find((o) => o.value === config?.location?.toLowerCase()) || null}
          onChange={(selected) => {
            const val = selected?.value;
            setConfig({ ...config, location: val });
          }}
        />
      </Field>

      <Field label={"GCP Credentials"}>
        <SelectCredential provider={"gcp"} value={credentialId} onChange={(value) => setCredentialId(value)} />
      </Field>

      {config?.project && credentialId && credentialConfig && (
        <Field
          description={"Run these two commands in your Google Cloud Shell to grant the service account access to BigQuery."}
          label="Grant permissions"
        >
          <Code title="Add user role">
            <div>gcloud projects add-iam-policy-binding {config?.project} \ </div>
            <div>--member serviceAccount:{credentialConfig.client_email} \ </div>
            <div>--role roles/bigquery.user</div>
          </Code>
          <br />
          <Code title="Add dataViewer role">
            <div>gcloud projects add-iam-policy-binding {config?.project} \ </div>
            <div>--member serviceAccount:{credentialConfig.client_email} \ </div>
            <div>--role roles/bigquery.dataViewer</div>
          </Code>
        </Field>
      )}

      <FieldError error={error} />
    </>
  );
};

// https://cloud.google.com/bigquery/docs/locations
const LOCATION_OPTIONS = [
  {
    description: "United States",
    name: "us",
  },
  {
    description: "European Union",
    name: "eu",
  },
  {
    description: "South Carolina",
    name: "us-east1",
  },
  {
    description: "Northern Virginia",
    name: "us-east4",
  },
  {
    description: "Iowa",
    name: "us-central1",
  },
  {
    description: "Oregon",
    name: "us-west1",
  },
  {
    description: "Los Angeles",
    name: "us-west2",
  },
  {
    description: "Salt Lake City",
    name: "us-west3",
  },
  {
    description: "Las Vegas",
    name: "us-west4",
  },
  {
    description: "Taiwan",
    name: "asia-east1",
  },
  {
    description: "Tokyo",
    name: "asia-northeast1",
  },
  {
    description: "Singapore",
    name: "asia-southeast1",
  },
  {
    description: "Mumbai",
    name: "asia-south1",
  },
  {
    description: "Hong Kong",
    name: "asia-east2",
  },
  {
    description: "Osaka",
    name: "asia-northeast2",
  },
  {
    description: "Seoul",
    name: "asia-northeast3",
  },
  {
    description: "Seoul",
    name: "asia-northeast3",
  },
  {
    description: "Jakarta",
    name: "asia-southeast2",
  },
  {
    description: "Delhi",
    name: "asia-south2",
  },
  {
    description: "Finland",
    name: "europe-north1",
  },
  {
    description: "Belgium",
    name: "europe-west1",
  },
  {
    description: "London",
    name: "europe-west2",
  },
  {
    description: "Frankfurt",
    name: "europe-west3",
  },
  {
    description: "Netherlands",
    name: "europe-west4",
  },
  {
    description: "Zurich",
    name: "europe-west6",
  },
  {
    description: "Warsaw",
    name: "europe-central2",
  },
  {
    description: "Montréal",
    name: "northamerica-northeast1",
  },
  {
    description: "São Paulo",
    name: "southamerica-east1",
  },
  {
    description: "Sydney",
    name: "australia-southeast1",
  },
  {
    description: "Melbourne",
    name: "australia-southeast2",
  },
];
