import { Dispatch, SetStateAction, useMemo, useState } from "react";

import { set } from "lodash";

import { OrderBy } from "src/graphql";

export type TableConfigOptions = {
  limit?: number;
  page?: number;
  defaultSortKey?: string | null;
};

export type SortState = {
  sortKey: string | null;
  sortDirection: OrderBy;
};

type TableConfigResult<TOrderByType> = {
  page: number;
  limit: number;
  offset: number;
  orderBy: TOrderByType | undefined;
  sortKey: string | null;
  sortDirection: OrderBy;
  setPage: Dispatch<SetStateAction<number>>;
  onSort(sortKey: string): void;
};

/**
 * Determines next sort direction for a column.
 *
 * If no previous sort direction, it will derive the default sort direction.
 *
 * @param sortKey Path that will be sorted on.
 * @param prevSortDirection Previous sort direction
 * @returns {OrderBy} The direction to sort
 */
const getNextSortDirection = (prevSortDirection?: OrderBy) => {
  return prevSortDirection?.startsWith("asc") ? "desc" : "asc";
};

export const useTableConfig = <TOrderByType>({
  defaultSortKey = null,
  ...options
}: TableConfigOptions = {}): TableConfigResult<TOrderByType> => {
  // pagination
  const [page, setPage] = useState<number>(options?.page ?? 0);

  const limit = options?.limit ?? 50;
  const offset = limit * page;

  // sorting
  const [sort, setSort] = useState<SortState>({
    sortKey: defaultSortKey,
    sortDirection: "desc",
  });
  const orderBy = useMemo(() => {
    const key = sort.sortKey || defaultSortKey;

    if (!key) {
      return undefined;
    }

    return set<TOrderByType>({}, key, sort.sortDirection);
  }, [sort, defaultSortKey]);

  const onSort = (sortKey: string) => {
    setSort(({ sortKey: prevSortKey, sortDirection }) => {
      if (sortKey !== prevSortKey) {
        return { sortKey, sortDirection: "desc" };
      }

      return {
        sortKey,
        sortDirection: getNextSortDirection(sortDirection),
      };
    });

    setPage(0);
  };

  return { page, limit, offset, orderBy, sortKey: sort.sortKey, sortDirection: sort.sortDirection, setPage, onSort };
};
