import { useEffect, useState } from "react";

import { isEqual } from "lodash";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import { Grid, Text } from "theme-ui";

import { KeyValueMapping } from "src/components/destinations/key-value-mapping";
import { SidebarForm } from "src/components/page";
import { useCreateDbtSyncConfigMutation, useDbtSyncConfigQuery, useUpdateDbtSyncConfigMutation } from "src/graphql";
import { Badge } from "src/ui/badge";
import { Row, Column } from "src/ui/box";
import { Button } from "src/ui/button";
import { Circle } from "src/ui/circle";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Spinner } from "src/ui/loading";
import { Message } from "src/ui/message";
import { Select } from "src/ui/select";
import { Toggle } from "src/ui/toggle";

import { RepositorySelector } from "../git/repository-selector";

export const DbtSyncConfig = ({ sourceId }) => {
  const { addToast } = useToasts();

  const [dbtSyncEnabled, setDbtSyncEnabled] = useState<boolean>();
  const [saveLoading, setSaveLoading] = useState(false);
  const [gitCredentialId, setGitCredentialId] = useState<string>();
  const [selector, setSelector] = useState<string | undefined | null>();
  const [path, setPath] = useState<string | undefined | null>();
  const [target, setTarget] = useState<string | undefined | null>();
  const [branch, setBranch] = useState<string | undefined | null>();
  const [version, setVersion] = useState<string | undefined | null>();
  const [envVars, setEnvVars] = useState({});
  const [repository, setRepository] = useState<string | undefined | null>();
  const [defaultSchema, setDefaultSchema] = useState<string>();

  const { mutateAsync: updateDbtSyncConfig } = useUpdateDbtSyncConfigMutation();
  const { mutateAsync: createDbtSyncConfig } = useCreateDbtSyncConfigMutation();

  const { data: dbtSyncConfigsData, isLoading: dbtSyncLoading } = useDbtSyncConfigQuery(
    {
      sourceId,
    },
    { enabled: Boolean(sourceId), refetchInterval: 3000 },
  );

  const dbtSyncConfig = dbtSyncConfigsData?.dbt_sync_config?.[0];

  useEffect(() => {
    let enabled = false;

    if (dbtSyncConfig) {
      enabled = dbtSyncConfig.enabled || false;
      setGitCredentialId(dbtSyncConfig?.git_credentials_id != null ? String(dbtSyncConfig?.git_credentials_id) : undefined);
      setSelector(dbtSyncConfig?.selector);
      setPath(dbtSyncConfig?.path);
      setTarget(dbtSyncConfig?.target);
      setBranch(dbtSyncConfig?.branch);
      setRepository(dbtSyncConfig?.repository);
      setDefaultSchema(dbtSyncConfig?.default_schema);
      setVersion(dbtSyncConfig?.version);
      setEnvVars(dbtSyncConfig?.env_vars);
    }
    setDbtSyncEnabled(enabled);
  }, [dbtSyncConfig]);

  const save = async () => {
    setSaveLoading(true);

    try {
      if (dbtSyncConfig) {
        await updateDbtSyncConfig({
          id: dbtSyncConfig.id,
          object: {
            repository,
            branch,
            target,
            path,
            selector,
            version,
            default_schema: defaultSchema,
            git_credentials_id: gitCredentialId,
            enabled: dbtSyncEnabled,
            env_vars: envVars,
          },
        });
      } else {
        await createDbtSyncConfig({
          object: {
            repository,
            branch,
            path,
            target,
            selector,
            version,
            default_schema: defaultSchema,
            git_credentials_id: gitCredentialId,
            enabled: dbtSyncEnabled,
            connection_id: sourceId,
            env_vars: envVars,
          },
        });
      }

      addToast("Your dbt sync settings for this source has been saved.", {
        appearance: "success",
      });
    } finally {
      setSaveLoading(false);
    }
  };

  const dirty =
    String(gitCredentialId) !== String(dbtSyncConfig?.git_credentials_id) ||
    selector !== dbtSyncConfig?.selector ||
    path !== dbtSyncConfig?.path ||
    target !== dbtSyncConfig?.target ||
    branch !== dbtSyncConfig?.branch ||
    repository !== dbtSyncConfig?.repository ||
    version !== dbtSyncConfig?.version ||
    defaultSchema !== dbtSyncConfig?.default_schema ||
    dbtSyncEnabled !== dbtSyncConfig?.enabled ||
    !isEqual(envVars, dbtSyncConfig?.env_vars);

  const complete = gitCredentialId && branch && repository && defaultSchema && version;

  const SUPPORTED_DBT_VERSIONS = [
    { label: "0.18", value: "0.18" },
    { label: "0.19", value: "0.19" },
    { label: "0.20", value: "0.20" },
    { label: "0.21", value: "0.21" },
    { label: "1.0", value: "1.0" },
    { label: "1.1", value: "1.1" },
  ];

  if (dbtSyncLoading) {
    return <Spinner size={64} />;
  }

  return (
    <Row sx={{ alignItems: "flex-start" }}>
      <Grid gap={8} sx={{ flexGrow: 1, mr: 8 }}>
        <Column>
          <Row sx={{ alignItems: "center", mb: 2 }}>
            <Text sx={{ fontWeight: "semi", fontSize: 3, mr: 4 }}>dbt Sync</Text>
            <Toggle sx={{ mr: 4 }} value={dbtSyncEnabled} onChange={setDbtSyncEnabled} />
          </Row>
          {dbtSyncEnabled && (
            <Badge sx={{ alignItems: "center" }} variant="base">
              {!dbtSyncConfig || dbtSyncConfig?.last_run_at ? (
                <Circle color={dbtSyncConfig?.last_run_at ? (dbtSyncConfig?.error ? "red" : "green") : "gray"} radius="12px" />
              ) : (
                <Spinner size={18} />
              )}
              <Text sx={{ ml: 2 }}>
                {dbtSyncConfig?.last_run_at
                  ? moment(dbtSyncConfig?.last_run_at).calendar()
                  : dbtSyncConfig
                  ? "Waiting to be synced"
                  : "Not synced"}
              </Text>
            </Badge>
          )}
        </Column>

        {dbtSyncConfig?.error && (
          <Message sx={{ width: "100%" }} variant="error">
            <Text as="pre" sx={{ wordBreak: "break-all", whiteSpace: "pre-wrap" }}>
              {JSON.stringify(dbtSyncConfig?.error, null, 2)}
            </Text>
          </Message>
        )}
        {dbtSyncEnabled && (
          <>
            <RepositorySelector
              branch={branch}
              gitCredentialId={gitCredentialId}
              repository={repository}
              setBranch={setBranch}
              setGitCredentialId={setGitCredentialId}
              setRepository={setRepository}
            />
            <Field label="dbt Version">
              <Select
                options={SUPPORTED_DBT_VERSIONS}
                placeholder="Select a version..."
                value={SUPPORTED_DBT_VERSIONS?.find((o) => o.value === version) || null}
                onChange={(selected) => {
                  const val = selected?.value;
                  setVersion(val);
                }}
              />
            </Field>
            <Field
              description="The default schema is the schema where dbt would normally materialize your tables to unless otherwise specified in project file. Generally, this is something like public or production or dbt_production."
              label="Default Schema"
            >
              <Input value={defaultSchema} onChange={setDefaultSchema} />
            </Field>
            <Field
              optional
              description="By default, Hightouch will select all the models. Specify a dbt selector here such as tag:hightouch or *."
              label="dbt Selector"
            >
              <Input value={selector} onChange={setSelector} />
            </Field>
            <Field
              optional
              description="By default, Hightouch will look for the dbt_project.yml file in the directory. Specify a custom path here if there are multiple dbt_project.yml files."
              label="Custom Path"
            >
              <Input value={path} onChange={setPath} />
            </Field>
            <Field
              optional
              description='By default, Hightouch uses the target name "prod" with the database credentials from the source. This is useful if you use a specific target.name variable in your dbt models.'
              label="Custom Target"
            >
              <Input value={target} onChange={setTarget} />
            </Field>
            <Field
              optional
              description="If set, Hightouch will run dbt commands with these environment variables."
              label="Custom Environment Variables"
            >
              <KeyValueMapping
                mapping={envVars}
                setMapping={(map) => {
                  setEnvVars(map);
                }}
              />
            </Field>
          </>
        )}
      </Grid>
      <SidebarForm
        buttons={
          <Button
            disabled={!dirty || !complete}
            label="Save changes"
            loading={saveLoading}
            sx={{ width: "100%" }}
            onClick={save}
          />
        }
        docsUrl={`${import.meta.env.VITE_DOCS_URL}/models/dbt-models/#configuring-dbt-model-selector`}
        name="dbt"
      />
    </Row>
  );
};
