import { createContext, useContext, FC } from "react";

import { ResourcePermission } from "src/graphql";

import { useUser } from "./user-context";

type Props = {
  permissions: ResourcePermission[];
};

export type PermissionContextType = {
  unauthorized: boolean;
  permissions: ResourcePermission[];
};

const defaultContextValue = {
  unauthorized: false,
  permissions: [],
};

export const PermissionContext = createContext<PermissionContextType>(defaultContextValue);

export const usePermission = () => useContext(PermissionContext);

export const PermissionProvider: FC<Readonly<Props>> = ({ children, permissions }) => {
  const { hasPermissions } = useUser();

  return (
    <PermissionContext.Provider value={{ unauthorized: permissions ? !hasPermissions(permissions) : false, permissions }}>
      {children}
    </PermissionContext.Provider>
  );
};
