import { useUser } from "src/contexts/user-context";
import { Checkbox } from "src/ui/checkbox";
import { Field, FieldError } from "src/ui/field";
import { Input } from "src/ui/input";
import { SensitiveField } from "src/ui/sensitive-field";

export const GenericForm = ({ config, setConfig, error, tunnel, hideSecret = false }) => {
  const { featureFlags } = useUser();

  return (
    <>
      <Field label="Host">
        <Input
          disabled={tunnel}
          value={tunnel ? `Using ${tunnel?.name} tunnel` : config?.host || ""}
          onChange={(value) => setConfig({ ...config, host: value })}
        />
      </Field>
      <Field label="Port">
        <Input
          disabled={tunnel}
          value={tunnel ? `Using ${tunnel?.name} tunnel` : config?.port || ""}
          onChange={(value) => setConfig({ ...config, port: value })}
        />
      </Field>
      <Field label="Database">
        <Input value={config?.database || ""} onChange={(value) => setConfig({ ...config, database: value })} />
      </Field>
      <Field label="User">
        <Input value={config?.user || ""} onChange={(value) => setConfig({ ...config, user: value })} />
      </Field>

      <SensitiveField
        hideSecret={hideSecret}
        label="Password"
        value={config?.password || ""}
        onChange={(password) => setConfig({ ...config, password })}
      />

      {featureFlags?.allowNonSSL && (
        <Checkbox
          label="Allow non-SSL connections"
          value={config?.ssl === false}
          onChange={(rejectUnauthorized) => {
            if (rejectUnauthorized) {
              setConfig({ ...config, ssl: false });
            } else {
              setConfig({ ...config, ssl: true });
            }
          }}
        />
      )}

      <FieldError error={error} />
    </>
  );
};
