import {
  useAthenaDatabasesNewSourceQuery,
  useAthenaDatabasesQuery,
  useAthenaDataCatalogsNewSourceQuery,
  useAthenaDataCatalogsQuery,
  useAthenaWorkGroupsNewSourceQuery,
  useAthenaWorkGroupsQuery,
} from "src/graphql";
import { Field, FieldError } from "src/ui/field";
import { Input } from "src/ui/input";
import { Select } from "src/ui/select";
import { SensitiveField } from "src/ui/sensitive-field";
import AWS_REGION_OPTIONS from "src/utils/aws-region-options";

import { SelectCredential } from "../../credentials";

export const AthenaForm = ({ source, config, setConfig, credentialId, setCredentialId, error, hideSecret = false }) => {
  // Do we have credentials, either from the current config or from the source
  // in the DB, so that we can query AWS?
  const credentialsProvided = (config?.region && (credentialId || (config?.accessKeyId && config?.apiSecret))) || source?.id;

  // These wrappers around useRefetchQuery run different queries depending on
  // whether the source is already defined in the DB.
  // If it is, the wrapper queries with the source ID as input, and the
  // middleware enriches the query with full source config (including secrets).
  // The current values in the form override the source config from the DB.
  const { getWorkGroups, workGroupOpts, workGroupsError, loadingWorkGroups } = useWorkGroupsQuery(
    source,
    config,
    credentialId,
    credentialsProvided,
  );

  const { getDataCatalogs, dataCatalogOpts, dataCatalogsError, loadingDataCatalogs } = useDataCatalogsQuery(
    source,
    config,
    credentialId,
    credentialsProvided,
  );

  const { getDatabases, databaseOpts, databasesError, loadingDatabases } = useDatabasesQuery(
    source,
    config,
    credentialId,
    credentialsProvided,
  );

  return (
    <>
      <Field label="Region">
        <Select
          options={AWS_REGION_OPTIONS}
          placeholder="Select a region..."
          value={AWS_REGION_OPTIONS?.find((o) => o.value === config?.region) || null}
          onChange={(selected) => {
            const val = selected?.value;
            setConfig({ ...config, region: val });
          }}
        />
      </Field>

      {!config?.accessKeyId && (
        <Field label={"AWS Credentials"}>
          <SelectCredential provider={"aws"} value={credentialId} onChange={(value) => setCredentialId(value)} />
        </Field>
      )}

      {config?.accessKeyId && (
        <>
          <Field label="AWS Access Key ID">
            <Input value={config?.accessKeyId || ""} onChange={(accessKeyId) => setConfig({ ...config, accessKeyId })} />
          </Field>

          <SensitiveField
            hideSecret={hideSecret}
            label="AWS Secret Access Key"
            value={config?.apiSecret || ""}
            onChange={(apiSecret) => setConfig({ ...config, apiSecret })}
          />
        </>
      )}

      {credentialsProvided && (
        <>
          <Field error={workGroupsError?.message || error?.workGroup} label="Work Group">
            <Select
              isLoading={loadingWorkGroups}
              options={workGroupOpts}
              placeholder="Select a work group..."
              reload={() => getWorkGroups()}
              value={config?.workGroup || ""}
              onChange={(selected) => setConfig({ ...config, workGroup: selected?.value })}
            />
          </Field>

          <Field
            description="Required if not specified in the Athena work group result configuration."
            error={error?.outputLocation}
            label="Output Location"
          >
            <Input
              placeholder="s3://my-output-location"
              value={config?.outputLocation || ""}
              onChange={(outputLocation) => setConfig({ ...config, outputLocation })}
            />
          </Field>

          <Field error={dataCatalogsError?.message || error?.dataCatalog} label="Data Catalog">
            <Select
              isLoading={loadingDataCatalogs}
              options={dataCatalogOpts}
              placeholder="Select a data catalog..."
              reload={getDataCatalogs}
              value={config?.dataCatalog || ""}
              onChange={(selected) => setConfig({ ...config, dataCatalog: selected?.value })}
            />
          </Field>

          {config?.dataCatalog && (
            <Field error={databasesError?.message || error?.database} label="Database">
              <Select
                isLoading={loadingDatabases}
                options={databaseOpts}
                placeholder="Select a database..."
                reload={getDatabases}
                value={config?.database || ""}
                onChange={(selected) => setConfig({ ...config, database: selected?.value })}
              />
            </Field>
          )}
        </>
      )}

      <FieldError error={error} />
    </>
  );
};

function useWorkGroupsQuery(source, config, credentialId, credentialsProvided: boolean) {
  const variables = getBaseVars(config, credentialId);

  const { data, error, isFetching, refetch } = useAthenaWorkGroupsQuery(
    {
      connectionId: source?.id,
      ...variables,
    },
    { enabled: Boolean(source) },
  );

  const {
    data: workGroupsNewData,
    error: workGroupsNewError,
    isFetching: loadingWorkGroupsNew,
    refetch: getWorkGroupsNew,
  } = useAthenaWorkGroupsNewSourceQuery(variables, { enabled: Boolean(credentialsProvided) });

  const workGroups = source
    ? data?.athenaListWorkGroups?.workGroups
    : workGroupsNewData?.athenaListWorkGroupsNewSource?.workGroups;
  const workGroupsError = source ? error : workGroupsNewError;
  const loadingWorkGroups = source ? isFetching : loadingWorkGroupsNew;
  const getWorkGroups = source ? refetch : getWorkGroupsNew;

  const workGroupOpts = getAthenaOpts(workGroups);

  return { getWorkGroups, workGroupOpts, workGroupsError, loadingWorkGroups };
}

function useDataCatalogsQuery(source, config, credentialId, credentialsProvided: boolean) {
  const variables = getBaseVars(config, credentialId);

  const {
    data: dataCatalogsData,
    error: dataCatalogsError,
    isFetching: loadingDataCatalogs,
    refetch: getDataCatalogs,
  } = useAthenaDataCatalogsQuery(
    {
      ...variables,
      connectionId: Number(source?.id),
    },
    { enabled: Boolean(source && credentialsProvided) },
  );

  const {
    data: dataCatalogsNewData,
    error: dataCatalogsNewError,
    isFetching: loadingNewDataCatalogs,
    refetch: getDataCatalogsNew,
  } = useAthenaDataCatalogsNewSourceQuery(variables, { enabled: Boolean(!source && credentialsProvided) });

  const dataCatalogs = source
    ? dataCatalogsData?.athenaListDataCatalogs?.dataCatalogs
    : dataCatalogsNewData?.athenaListDataCatalogsNewSource.dataCatalogs;

  const dataCatalogOpts = getAthenaOpts(dataCatalogs);

  return {
    getDataCatalogs: source ? getDataCatalogs : getDataCatalogsNew,
    dataCatalogOpts,
    dataCatalogsError: source ? dataCatalogsError : dataCatalogsNewError,
    loadingDataCatalogs: source ? loadingDataCatalogs : loadingNewDataCatalogs,
  };
}

function useDatabasesQuery(source, config, credentialId, credentialsProvided: boolean) {
  const variables = { ...getBaseVars(config, credentialId), dataCatalog: config?.dataCatalog };

  const enabled = credentialsProvided && config?.dataCatalog;

  const {
    data: databasesData,
    error: databasesError,
    isFetching: loadingDatabases,
    refetch: getDatabases,
  } = useAthenaDatabasesQuery(
    {
      connectionId: Number(source?.id),
      ...variables,
    },
    { enabled: Boolean(source && enabled) },
  );

  const {
    data: databasesNewData,
    error: databasesNewError,
    isFetching: loadingNewDatabases,
    refetch: getDatabasesNew,
  } = useAthenaDatabasesNewSourceQuery(variables, { enabled: Boolean(!source && enabled) });

  const databases = source
    ? databasesData?.athenaListDatabases?.databases
    : databasesNewData?.athenaListDatabasesNewSource?.databases;

  const databaseOpts = getAthenaOpts(databases);

  return {
    getDatabases: source ? getDatabases : getDatabasesNew,
    databaseOpts,
    databasesError: source ? databasesError : databasesNewError,
    loadingDatabases: source ? loadingDatabases : loadingNewDatabases,
  };
}

function getBaseVars(config, credentialId?: number) {
  return {
    region: config?.region,

    credentials: {
      credentialId: credentialId,

      // DEPRECATED
      accessKeyId: config?.accessKeyId,
      apiSecret: config?.apiSecret,
    },
  };
}

function getAthenaOpts(queryResults: { name: string }[] | undefined) {
  const athenaOpts = queryResults?.map((q) => ({ label: q.name, value: q.name })) || [];
  return athenaOpts;
}
