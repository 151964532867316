import { FC } from "react";

import { startCase } from "lodash";

import { useGithubBranchesQuery, useGitCredentialsQuery, useGithubRepositoriesQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Select } from "src/ui/select";

type RepositorySelectorProps = {
  gitCredentialId: string | undefined;
  repository: string | undefined | null;
  branch: string | undefined | null;
  setGitCredentialId: (credential: string) => void;
  setRepository: (repository: string) => void;
  setBranch: (repository: string) => void;
};

export const RepositorySelector: FC<Readonly<RepositorySelectorProps>> = ({
  gitCredentialId,
  repository,
  branch,
  setGitCredentialId,
  setRepository,
  setBranch,
}) => {
  const { data: gitData, isLoading: gitLoading } = useGitCredentialsQuery();
  const credentialOptions = gitData?.git_credentials?.map((creds) => ({
    label: `${startCase(creds?.type)}: ${
      creds?.type === "github_app"
        ? creds?.credentials?.installationId
        : creds?.username != ""
        ? creds?.username
        : "SSH Privatekey"
    }`,
    value: creds.id,
  }));

  const selectedGitCredential = gitData?.git_credentials?.find((cred) => String(cred.id) === String(gitCredentialId));

  const { data: githubRepoData, isLoading: githubRepoLoading } = useGithubRepositoriesQuery(
    {
      credentialId: String(selectedGitCredential?.id),
    },
    {
      enabled: !(
        !selectedGitCredential?.id ||
        (selectedGitCredential?.type !== "github" && selectedGitCredential?.type !== "github_app")
      ),
    },
  );

  const githubRepoOptions = githubRepoData?.getGithubRepositories?.map((repo) => ({
    label: repo.fullName,
    value: repo.htmlUrl,
  }));

  const selectedGithubRepo = githubRepoData?.getGithubRepositories?.find((repo) => repo.htmlUrl === repository);

  const { data: githubBranchData, isLoading: githubBranchLoading } = useGithubBranchesQuery(
    {
      credentialId: String(selectedGitCredential?.id),
      repo: selectedGithubRepo?.fullName ?? "",
    },
    {
      enabled: !(
        !selectedGitCredential?.id ||
        (selectedGitCredential?.type !== "github" && selectedGitCredential?.type !== "github_app") ||
        !selectedGithubRepo?.fullName
      ),
    },
  );

  const githubBranchOptions = githubBranchData?.getGithubBranches?.map((branch) => ({
    label: branch.name,
    value: branch.name,
  }));

  return (
    <>
      <Field label="Git Credentials">
        <Select
          isLoading={gitLoading}
          options={credentialOptions}
          placeholder="Select a service..."
          value={credentialOptions?.find((o) => o.value === gitCredentialId) || null}
          onChange={(selected) => {
            const val = selected?.value;
            setGitCredentialId(val);
          }}
        />
      </Field>

      <Field
        description={
          selectedGitCredential?.type === "github"
            ? undefined
            : "Please use the full link of the git repository, including https."
        }
        label="Repository"
      >
        {selectedGitCredential?.type === "github" || selectedGitCredential?.type === "github_app" ? (
          <Select
            isLoading={githubRepoLoading}
            options={githubRepoOptions}
            placeholder="Select a repository..."
            value={githubRepoOptions?.find((o) => o.value === repository) || null}
            onChange={(selected) => {
              const val = selected?.value;
              setRepository(val);
            }}
          />
        ) : (
          <Input value={repository} onChange={setRepository} />
        )}
      </Field>

      <Field label="Branch">
        {selectedGitCredential?.type === "github" || selectedGitCredential?.type === "github_app" ? (
          <Select
            isLoading={githubBranchLoading}
            options={githubBranchOptions}
            placeholder="Select a branch..."
            value={githubBranchOptions?.find((o) => o.value === branch) || null}
            onChange={(selected) => {
              const val = selected?.value;
              setBranch(val);
            }}
          />
        ) : (
          <Input value={branch} onChange={setBranch} />
        )}
      </Field>
    </>
  );
};
