import { FC } from "react";

import { Grid } from "theme-ui";

export const Form: FC = ({ children }) => {
  return (
    <Grid gap={12} sx={{ "& > div:not(:last-of-type)": { pb: 12, borderBottom: "small" }, width: "100%" }}>
      {children}
    </Grid>
  );
};
