import { FC, useRef } from "react";

import { Box, Text } from "theme-ui";
import { useClipboard } from "use-clipboard-copy";

import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { CheckIcon, CopyIcon } from "src/ui/icons";

export interface CodeProps {
  title?: string;
}

export const Code: FC<Readonly<CodeProps>> = ({ children, title }) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const clipboard = useClipboard({
    copiedTimeout: 600,
  });
  const copyCode = () => {
    const text = contentRef.current?.innerText;

    if (typeof text === "string") {
      clipboard.copy(text);
    }
  };
  return (
    <Box sx={{ bg: "base.0", borderRadius: 2 }}>
      <Row
        sx={{
          justifyContent: "space-between",
          bg: "base.1",
          px: 4,
          borderBottom: "small",
          borderColor: "base.2",
          alignItems: "center",
          height: "48px",
          borderTopLeftRadius: 2,
          borderTopRightRadius: 2,
        }}
      >
        <Text sx={{ mr: 8, fontSize: 1, fontWeight: "semi" }}>{title}</Text>
        {clipboard.copied ? (
          <CheckIcon color="green" size={18} />
        ) : (
          <Button variant="plain" onClick={copyCode}>
            <CopyIcon size={16} />
          </Button>
        )}
      </Row>
      <Box ref={contentRef} sx={{ p: 4, fontFamily: "code" }}>
        {children}
      </Box>
    </Box>
  );
};
