import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const OnboardingIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M16.884 5.115h-2.943a2.43 2.43 0 01-1.894-.887l-.969-1.34A2.367 2.367 0 009.193 2h-2.08C3.378 2 2 4.192 2 7.92v4.028c-.005.443 19.996.443 19.997 0v-1.17c.018-3.728-1.325-5.662-5.113-5.662z"
        opacity={0.4}
      />
      <path
        clipRule="evenodd"
        d="M20.832 6.543c.32.374.567.805.73 1.27.318.954.465 1.957.435 2.963v5.253a9.346 9.346 0 01-.098 1.322 5.992 5.992 0 01-.8 2.183 4.438 4.438 0 01-.676.887 6 6 0 01-4.366 1.571H7.931a6.027 6.027 0 01-4.375-1.57 4.44 4.44 0 01-.667-.888 5.832 5.832 0 01-.782-2.183A8.2 8.2 0 012 16.03v-5.253c0-.439.024-.877.071-1.313.01-.077.025-.152.04-.227.025-.124.049-.246.049-.368.09-.526.255-1.037.49-1.517.693-1.482 2.115-2.236 4.445-2.236h9.78c1.305-.101 2.6.292 3.628 1.1.119.1.229.21.33.328zM6.97 15.541h10.083a.828.828 0 00.863-.799.744.744 0 00-.178-.532.783.783 0 00-.622-.32H6.97a.826.826 0 100 1.651z"
        fillRule="evenodd"
      />
    </Icon>
  );
};
