import { FC } from "react";

import { ThemeUIStyleObject } from "theme-ui";

import { Container, ContainerSize } from "src/ui/box";

export type Props = {
  size?: ContainerSize;
  sx?: ThemeUIStyleObject;
};

export const PageContainer: FC<Readonly<Props>> = ({ children, sx = {}, size = "large" }) => {
  return (
    <Container
      size={size}
      sx={{
        pt: 6,
        px: 16,
        pb: 26,
        ...sx,
      }}
    >
      {children}
    </Container>
  );
};
