import { FC } from "react";

import ReactToggle from "react-toggle";
import { Flex, Text, ThemeUIStyleObject } from "theme-ui";

import "react-toggle/style.css";
import { usePermission } from "src/contexts/permission-context";

export interface ToggleProps {
  value?: boolean;
  onChange: (value: boolean) => void;
  label?: string;
  sx?: ThemeUIStyleObject;
  disabled?: boolean;
}

export const Toggle: FC<Readonly<ToggleProps>> = ({ value, onChange, label, disabled, sx }) => {
  const permission = usePermission();

  return (
    <Flex sx={{ alignItems: "center", ...sx }}>
      {label && (
        <Text sx={{ textTransform: "uppercase", mr: 2, fontSize: "10px", color: "base.4", fontWeight: "bold" }}>{label}</Text>
      )}
      <ReactToggle
        checked={value}
        disabled={disabled || permission?.unauthorized}
        icons={false}
        onChange={() => onChange(!value)}
      />
    </Flex>
  );
};
