import { FC, useMemo } from "react";

import { ThemeUIStyleObject } from "theme-ui";

import { Column } from "src/ui/box";

export enum CardSize {
  Large = "large",
}

export interface CardProps {
  size?: CardSize;
  sx?: ThemeUIStyleObject;
}

const baseStyle: ThemeUIStyleObject = {
  bg: "white",
  border: "small",
  borderRadius: 4,
};

const variants: Record<CardSize, ThemeUIStyleObject> = {
  [CardSize.Large]: {
    p: 10,
  },
};

export const Card: FC<Readonly<CardProps>> = ({ children, size = "large", sx = {} }) => {
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...variants[size],
      ...sx,
    }),
    [size, sx],
  );

  return <Column sx={style}>{children}</Column>;
};
