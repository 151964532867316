import { useState, FC, VFC } from "react";

import { useToasts } from "react-toast-notifications";
import { Text } from "theme-ui";

import { useSetupWarehousePlanningMutation, useUpdateSourceMutation } from "src/graphql";
import { Row, Column } from "src/ui/box";
import { Button } from "src/ui/button";
import { Heading } from "src/ui/heading";
import { Link } from "src/ui/link";
import { Message } from "src/ui/message";
import { load, save } from "src/utils/storage";

import { Field, FieldError } from "../../ui/field";
import { Input } from "../../ui/input";

type Props = {
  id: string;
  enabled?: boolean;
  editPlannerDatabase: boolean;
  defaultPlannerDatabase: string;
};

const storageKey = "warehouse-planning-hidden";

export const WarehousePlanning: VFC<Readonly<Props>> = ({ id, enabled, editPlannerDatabase, defaultPlannerDatabase }) => {
  const { addToast } = useToasts();
  const [hidden, setHidden] = useState<boolean>(Boolean(load(storageKey)));
  const [loading, setLoading] = useState<boolean>(false);
  const [plannerDatabase, setPlannerDatabase] = useState<string>(defaultPlannerDatabase);
  const [show, setShow] = useState<boolean>(false);
  const [enabledError, setEnabledError] = useState<string>();

  const { mutateAsync: setup, error } = useSetupWarehousePlanningMutation();
  const { mutateAsync: updateSource } = useUpdateSourceMutation();

  const enable = async () => {
    setLoading(true);
    await setup(
      {
        id,
        plannerDatabase,
      },
      {
        onSuccess: async () => {
          await updateSource({
            id,
            set: {
              plan_in_warehouse: true,
              plan_in_warehouse_config: {
                plannerDatabase,
              },
            },
            append: {},
          });

          setLoading(false);

          addToast(`Warehouse planning enabled!`, {
            appearance: "success",
          });
        },
        onError: (error) => {
          setEnabledError(error.message);
          setLoading(false);
        },
      },
    );
  };

  const hide = () => {
    save(storageKey, true);
    setHidden(true);
  };

  const edit = () => {
    setShow(true);
  };

  if (enabled && !show) {
    return (
      <Row
        sx={{
          border: "small",
          py: 8,
          px: 4,
          borderRadius: 2,
          alignItems: "center",
          justifyContent: "space-between",
          maxWidth: "700px",
        }}
      >
        <Text sx={{ mr: 2 }}>Warehouse planning is enabled.</Text>
        <Row sx={{ justifyContent: "space-between" }}>
          <Row>
            <Button loading={loading} sx={{ mr: 1 }} variant="dark" onClick={edit}>
              Edit
            </Button>
          </Row>
          <Row variant="secondary">
            <LearnMore />
          </Row>
        </Row>
      </Row>
    );
  }

  if (hidden) {
    return (
      <Column sx={{ border: "small", p: 4, borderRadius: 2 }}>
        <Row
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            maxWidth: "440px",
          }}
        >
          <Text sx={{ mr: 2 }}>Enable warehouse planning</Text>
          <Row>
            <LearnMore />
            <Button loading={loading} sx={{ ml: 2 }} variant="dark" onClick={enable}>
              Enable
            </Button>
          </Row>
        </Row>
        {editPlannerDatabase && <PlannerDatabase plannerDatabase={plannerDatabase} onChange={setPlannerDatabase} />}
        {error && (
          <Column>
            <FieldError error={enabledError} />
            <Message sx={{ mt: 4 }} variant="error">
              Failed to enable. Error: {enabledError}. Your account might not have write permissions. See our{" "}
              <DocsLink>docs</DocsLink> for instructions.
            </Message>
          </Column>
        )}
      </Column>
    );
  }

  return (
    <Column sx={{ border: "small", p: 4, borderRadius: 2 }}>
      <Heading variant="h3">Enable warehouse planning</Heading>
      <Text sx={{ mt: 2, mb: 8, color: "base.7" }}>
        Warehouse-level planning improves sync speed by doing more computation in your data warehouse.
        <br />
        <br />
        See our{" "}
        <Link to={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-planning/#migrating-from-remote-diffing`}>
          migration docs
        </Link>{" "}
        for tips on migrating existing syncs to warehouse planning.
      </Text>
      {editPlannerDatabase && <PlannerDatabase plannerDatabase={plannerDatabase} onChange={setPlannerDatabase} />}
      {error ? (
        <Message variant="error">
          Failed to enable. Error: {enabledError} Your account might not have write permissions. See our{" "}
          <DocsLink>docs</DocsLink> for instructions.
        </Message>
      ) : (
        <Message>
          Warehouse planning requires write permissions, see our <DocsLink>docs</DocsLink> for detailed instructions on
          obtaining these.
        </Message>
      )}

      <Row sx={{ justifyContent: "space-between", mt: 6 }}>
        <Row>
          <Button loading={loading} sx={{ mr: 2 }} variant="dark" onClick={enable}>
            Enable
          </Button>
        </Row>
        <Button variant="secondary" onClick={hide}>
          Hide
        </Button>
      </Row>
    </Column>
  );
};

export const PlannerDatabase: VFC<Readonly<{ plannerDatabase: string | undefined; onChange: (string) => void }>> = ({
  plannerDatabase,
  onChange,
}) => {
  return (
    <Field
      description="Specify the database used for storing planning tables. If empty, Hightouch will use the default database from your source configuration"
      label="Planner Database"
      optional={true}
      sx={{ mt: 8, mb: 8 }}
    >
      <Input value={plannerDatabase} onChange={(value) => onChange(value)} />
    </Field>
  );
};

export const DocsLink: FC = ({ children }) => (
  <Link newTab to={`${import.meta.env.VITE_DOCS_URL}/working-with-data/warehouse-planning/`}>
    {children}
  </Link>
);

const LearnMore: VFC = () => (
  <DocsLink>
    <Button variant="secondary">Learn more</Button>
  </DocsLink>
);
