import { useState, VFC } from "react";

import { pickBy, isEqual } from "lodash";

import {
  AlertsOrderBy,
  useCreateSyncAlertsMutation,
  useCreateSyncTemplateAlertsMutation,
  useDeleteSyncAlertsMutation,
  useUpdateSyncMutation,
  useUpdateSyncTemplateMutation,
  useWorkspaceAlertsQuery,
  useWorkspaceAlertsWithStatusesQuery,
} from "src/graphql";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { useTableConfig } from "src/ui/table";

import { AlertSelector } from "../alerts/alert-selector";
import { Permission } from "../permission";

type Props = {
  alerts: any;
  rowThresholdTotal?: any;
  rowThresholdAttempted?: any;
  syncId?: any;
  templateId?: any;
};

export const SyncAlerts: VFC<Readonly<Props>> = ({ alerts, rowThresholdTotal, rowThresholdAttempted, syncId, templateId }) => {
  const processedAlerts = alerts?.reduce((obj, instance) => {
    if (!obj[instance.id]) {
      obj[instance.id] = {};
    }
    obj[instance.id]["row"] = instance.row_error ?? null;
    obj[instance.id]["fatal"] = instance.fatal_error ?? null;

    return obj;
  }, {});

  const [updating, setUpdating] = useState<boolean>(false);
  const [syncAlerts, setSyncAlerts] = useState<any>(processedAlerts);
  const [thresholds, setThresholds] = useState<any>({ total: rowThresholdTotal, attempted: rowThresholdAttempted });

  const { onSort, orderBy } = useTableConfig<AlertsOrderBy>({
    defaultSortKey: "name",
  });

  const { data: workspaceAlertsData } = useWorkspaceAlertsQuery(undefined, {
    enabled: Boolean(templateId),
  });

  const { data: workspaceAlertsWithStatusesData } = useWorkspaceAlertsWithStatusesQuery(
    { orderBy, syncId },
    { enabled: Boolean(syncId) },
  );

  const workspaceAlerts = (workspaceAlertsData || workspaceAlertsWithStatusesData)?.alerts || [];

  const { mutateAsync: upsertSyncAlerts } = useCreateSyncAlertsMutation();
  const { mutateAsync: upsertSyncTemplateAlerts } = useCreateSyncTemplateAlertsMutation();
  const { mutateAsync: deleteAlertInstances } = useDeleteSyncAlertsMutation();
  const { mutateAsync: updateSync } = useUpdateSyncMutation();
  const { mutateAsync: updateSyncTemplate } = useUpdateSyncTemplateMutation();

  const filteredSyncAlerts = pickBy(syncAlerts, (a) => {
    return a?.row !== null || a?.fatal !== null;
  });

  const update = async () => {
    setUpdating(true);

    const removedInstanceIds = Object.keys(syncAlerts).filter((i) => !Object.keys(filteredSyncAlerts).includes(i));

    const addedInstances = Object.entries(filteredSyncAlerts).map(([alertId, states]) => {
      return {
        destination_instance_id: syncId,
        sync_template_id: templateId,
        alert_id: alertId,
        fatal_error: states?.fatal,
        row_error: states?.row,
      };
    });

    if (removedInstanceIds.length) {
      await deleteAlertInstances({ syncId: syncId, syncTemplateId: templateId, alertIds: removedInstanceIds });
    }

    if (addedInstances.length) {
      if (syncId) {
        await upsertSyncAlerts({ input: addedInstances });
      } else {
        await upsertSyncTemplateAlerts({ input: addedInstances });
      }
    }

    if (rowThresholdAttempted !== thresholds?.attempted || rowThresholdTotal !== thresholds?.total) {
      if (syncId) {
        await updateSync({
          id: syncId,
          object: {
            row_threshold_attempted: thresholds.attempted,
            row_threshold_total: thresholds.total,
          },
        });
      } else {
        await updateSyncTemplate({
          id: templateId,
          input: {
            row_threshold_attempted: thresholds.attempted,
            row_threshold_total: thresholds.total,
          },
        });
      }
    }

    setUpdating(false);
  };

  const dirty =
    rowThresholdAttempted !== thresholds?.attempted ||
    rowThresholdTotal !== thresholds?.total ||
    !isEqual(filteredSyncAlerts, processedAlerts);

  return (
    <Row sx={{ width: "100%", justifyContent: "space-between" }}>
      <AlertSelector
        alerts={syncAlerts}
        orderBy={orderBy}
        showStatuses={Boolean(syncId)}
        thresholds={thresholds}
        workspaceAlerts={workspaceAlerts}
        onAlertsChange={setSyncAlerts}
        onSort={onSort}
        onThresholdsChange={setThresholds}
      />
      <Permission>
        <Button
          disabled={!dirty}
          loading={updating}
          sx={{ ml: 4, width: "208px", position: "sticky", top: 24 }}
          onClick={update}
        >
          Save
        </Button>
      </Permission>
    </Row>
  );
};
