import { FC, forwardRef, ReactNode, useEffect, useState } from "react";

import { isEqual } from "lodash";
import { matchSorter } from "match-sorter";
import ReactSelect from "react-select";
import { Flex, ThemeUIStyleObject } from "theme-ui";

import { usePermission } from "src/contexts/permission-context";

import { Indices } from "../../../../design";
import { colors } from "../../../../design/colors";
import { ReloadButton } from "../button";
import { SelectMenu } from "./menu";
import { MultiValue } from "./multi-value";
import { SelectOption } from "./option";
import { ValueContainer } from "./value";

export interface Option {
  [key: string]: any;
  label: string;
  value: any;
  sx?: ThemeUIStyleObject;
}

export const selectStyles = ({ size, width, isMulti = false, isError = false, readOnly = false, disabled = false }) => ({
  container: (base) => ({
    ...base,
    maxWidth: width,
    width,
    flex: 1,
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: "12px",
    color: colors.base[4],
  }),
  menuPortal: (base) => ({ ...base, zIndex: Indices.Popup + 1, width: "100%" }),
  menu: (base) => ({
    ...base,
    boxShadow: "none",
    borderRadius: "none",
    minWidth: "240px",
    width: "max-content",
    padding: 0,
    background: "inherit",
  }),
  menuList: (base) => ({
    ...base,
    width: "100%",
  }),
  valueContainer: (base) => ({
    ...base,
    color: "inherit",
    paddingLeft: size === "large" ? "12px" : "8px",
    paddingRight: "4px",
    maxHeight: "80px",
    overflowY: "auto",
  }),
  singleValue: (base) => ({
    ...base,
    color: "inherit",
  }),
  control: (base, state) => ({
    ...base,
    cursor: "pointer",
    width: "100%",
    fontSize: size === "large" ? "14px" : "12px",
    height: isMulti ? "max-content" : size === "large" ? "40px" : "32px",
    fontWeight: 400,
    minHeight: isMulti ? undefined : "unset",
    color: readOnly && !disabled ? undefined : state.isDisabled ? colors.base[5] : undefined,
    backgroundColor: state.isDisabled ? colors.base[1] : "white",
    boxShadow: "none",
    borderRadius: "4px",
    border: `1px solid ${isError ? colors.red : state.isFocused ? colors.primary : colors.base[2]}`,
    ":hover": {
      borderColor: state.isFocused ? colors.primary : isError ? colors.red : colors.base[3],
    },
    transition: "100ms border-color color",
  }),
  option: (base) => ({
    ...base,
    width: "100%",
    paddingLeft: "8px",
    paddingRight: "24px",
    backgroundColor: "transparent",
    color: "inherit",
    cursor: "pointer",
  }),
  indicatorsContainer: (base) => ({
    ...base,
  }),
  clearIndicator: () => ({ width: "20px", height: "20px", color: colors.base[3] }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    ...(readOnly ? { display: "none" } : {}),
    maxHeight: "22px",
    paddingTop: "0px",
    paddingBottom: "0px",
  }),
  multiValue: (base) => ({
    ...base,
    border: `1px solid ${colors.base[2]}`,
    backgroundColor: colors.base[0],
  }),
  multiValueRemove: (base) => ({
    ...base,
    ":hover": {
      backgroundColor: colors.base[5],
    },
  }),
});

export interface SelectProps {
  formatOptionLabel?: any;
  value?: any;
  options?: Option[];
  onChange?: (arg: any) => void;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  width?: string | number;
  isError?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  before?: any;
  after?: any;
  reload?: () => void;
  reloadTooltip?: string;
  sx?: ThemeUIStyleObject;
  empty?: ReactNode;
  tip?: ReactNode;
  size?: "large" | "small";
}

export const Select: FC<Readonly<SelectProps>> = forwardRef<ReactSelect, SelectProps>(
  (
    {
      size,
      options,
      placeholder,
      value,
      onChange,
      width = "100%",
      readOnly,
      disabled,
      isError,
      isLoading,
      isClearable,
      isMulti,
      before = null,
      after = null,
      formatOptionLabel,
      reload,
      reloadTooltip,
      empty,
      tip,
      sx = {},
    },
    ref,
  ) => {
    const grouped = options?.some((option) => Boolean(option.options));
    const [filteredOptions, setFilteredOptions] = useState(options || []);
    const permission = usePermission();

    useEffect(() => {
      setFilteredOptions(options || []);
    }, [options]);

    const onInputChange = (value, { action }) => {
      if (action === "input-change") {
        let filteredOptions;
        if (grouped) {
          filteredOptions = options?.map((g) => {
            return {
              ...g,
              options: matchSorter(g?.options, value, {
                keys: ["label"],
              }),
            };
          });
        } else {
          filteredOptions = matchSorter(options ?? [], value, {
            keys: ["label"],
          });
        }
        if (filteredOptions) {
          setFilteredOptions(filteredOptions);
        }
      } else if (action === "menu-close") {
        setFilteredOptions(options || []);
      }
    };

    const selectValue = getSelectValue(value, options ?? []);

    return (
      <Flex sx={{ alignItems: "center", flex: 1, ...sx }}>
        <ReactSelect
          ref={ref}
          afterValue={after}
          beforeValue={before}
          components={{ Option: SelectOption, ValueContainer, MultiValue, Menu: SelectMenu }}
          empty={empty}
          formatOptionLabel={formatOptionLabel}
          isClearable={isClearable}
          isDisabled={disabled || readOnly || permission?.unauthorized}
          isLoading={isLoading}
          isMulti={isMulti}
          isOptionDisabled={(option: Option) => option.disabled}
          menuPlacement="auto"
          menuPortalTarget={document.getElementById("portalAnchor")}
          options={filteredOptions}
          placeholder={placeholder}
          styles={selectStyles({ size, width, isError, readOnly: readOnly || permission?.unauthorized, disabled, isMulti })}
          tip={tip}
          value={selectValue}
          onChange={onChange}
          onInputChange={onInputChange}
        />
        {reload && !permission?.unauthorized && (
          <ReloadButton loading={isLoading} sx={{ ml: 2 }} tooltip={reloadTooltip} onClick={reload} />
        )}
      </Flex>
    );
  },
);

Select.displayName = "Select";

export const getSelectValue = (value: unknown | Option, options: Option[]): Option | Option[] | undefined | null => {
  if (options) {
    const flatOptions = flattenOptions(options);
    if (value !== undefined) {
      if (Array.isArray(value)) {
        return value.map(
          (v) =>
            flatOptions.find(
              (o) => isEqual(o.value, v) || isEqual(o, v) || (typeof value === "object" && isEqual(o, value?.["value"])),
            ) || { value: v, label: v },
        );
      }
      return flatOptions.find(
        (o) => isEqual(o.value, value) || isEqual(o, value) || (typeof value === "object" && isEqual(o, value?.["value"])),
      );
    }
  }
  return null;
};

export const flattenOptions = (options: Option[]): Option[] => {
  const flattenedOptions: Option[] = [];

  for (const item of options) {
    if (item.options) {
      flattenedOptions.unshift(...item.options);
    } else {
      flattenedOptions.unshift(item);
    }
  }

  return flattenedOptions;
};
