/* eslint-disable prettier/prettier */
import { StrictMode, Suspense } from "react";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactDOM from "react-dom";
import { ToastProvider } from "react-toast-notifications";

import { App } from "src/components/app";
import FeatureFlagProvider from "src/contexts/feature-flags";
import { UserProvider } from "src/contexts/user-context";
import { PageSpinner } from "src/ui/loading";
import { QueryProvider } from "src/utils/query-client";

import "react-ace";
import "ace-builds/src-min-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/theme-sqlserver";
import "ace-builds/src-min-noconflict/ext-language_tools";

import { ErrorBoundary } from "./components/error-boundary";
import { ThemeProvider } from "./theme";

Sentry.init({
  enabled: import.meta.env.PROD,
  dsn: "https://b61c707a79854bc2902b1392410fa6ae@o1224454.ingest.sentry.io/6505512",
  integrations: [new BrowserTracing()],
  // Don't use tracing for now
  tracesSampleRate: 0,
});

window.Hightouch = window.Hightouch || {};

ReactDOM.render(
  <StrictMode>
    <QueryProvider>
      <ThemeProvider>
        <ErrorBoundary>
          <Suspense fallback={<PageSpinner />}>
            <UserProvider>
              <ToastProvider autoDismiss={true} placement={"top-center"}>
                <FeatureFlagProvider>
                  <App />
                </FeatureFlagProvider>
              </ToastProvider>
            </UserProvider>
          </Suspense>
        </ErrorBoundary>
      </ThemeProvider>
    </QueryProvider>
  </StrictMode>,
  document.body,
);
