import { FC } from "react";

import { Grid } from "theme-ui";
import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import {
  useIterableCampaignsQuery,
  useIterableColumnsQuery,
  useIterableTemplatesQuery,
  useIterableGetCatalogsQuery,
  useIterableCatalogFieldsQuery,
} from "src/graphql";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { RadioGroup } from "src/ui/radio";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { COMMON_SCHEMAS, StandardFieldType } from "src/utils/destinations";

import { DeleteField } from "../delete-field";
import { FromIdField } from "../from-id-field";
import { IdMappingField } from "../id-mapping-field";
import { MappingsField } from "../mappings-field";
import { ModeField } from "../mode-field";
import { ObjectField } from "../object-field";
import { TypeField } from "../type-field";

export const validation = Yup.object().shape(
  {
    /* shared fields */
    type: Yup.string().required().default("object"),
    mode: Yup.string().required().default("upsert"),
    mappings: COMMON_SCHEMAS.mappings,
    // required when type is event and emailFrom is not set
    userIdFrom: Yup.mixed().when("type", {
      is: "event",
      then: Yup.mixed().when("emailFrom", {
        is: (v) => !v,
        then: Yup.mixed().required(),
        otherwise: Yup.mixed().notRequired(),
      }),
      otherwise: Yup.mixed().notRequired(),
    }),
    deleteMode: Yup.string().notRequired(),

    externalIdMapping: Yup.object().when("type", {
      is: "event",
      then: Yup.object().notRequired(),
      otherwise: COMMON_SCHEMAS.externalIdMapping,
    }),
    /* event fields */
    fromId: Yup.mixed().when("type", {
      is: "event",
      then: Yup.mixed().required(),
      otherwise: Yup.mixed().notRequired(),
    }),
    eventName: Yup.string().notRequired(),
    // required when type is event and userIdFrom is not set
    emailFrom: Yup.mixed().when("type", {
      is: "event",
      then: Yup.mixed().when("userIdFrom", {
        is: (v) => !v,
        then: Yup.mixed().required(),
        otherwise: Yup.mixed().notRequired(),
      }),
      otherwise: Yup.mixed().notRequired(),
    }),
    campaignId: Yup.number().notRequired(),
    templateId: Yup.number().notRequired(),
    customMappings: COMMON_SCHEMAS.mappings,
    rowsPerBatch: Yup.number().integer().positive().notRequired(),
    object: Yup.string().when("type", {
      is: "catalog",
      then: Yup.string().required(),
      otherwise: Yup.string().notRequired(),
    }),
  },
  [["userIdFrom", "emailFrom"]],
);

const TYPES = [
  { label: "Objects", value: "object" },
  { label: "Events", value: "event" },
  { label: "Catalog", value: "catalog" },
];

const MODES = {
  upsert: { label: "Upsert", value: "upsert" },
  update: { label: "Update", value: "update" },
};

const MODES_BY_TYPE = {
  object: [MODES.upsert, MODES.update],
  event: [MODES.upsert],
  catalog: [MODES.upsert],
};

const OBJECT_EXTERNAL_FIELDS = [{ label: "Iterable User ID", value: "userId" }];
const OBJECT_UPSERT_FIELDS = [{ label: "Email", value: "email" }];

const EVENT_EXTERNAL_FIELDS = [
  { label: "Event Name", value: "eventName", type: StandardFieldType.STRING },
  { label: "Campaign ID", value: "campaignId", type: StandardFieldType.NUMBER },
  { label: "Template ID", value: "templateId", type: StandardFieldType.NUMBER },
];

const CATALOG_ID_FIELDS = [{ label: "Unique Product ID", value: "productId", type: StandardFieldType.STRING }];

export const IterableForm: FC = () => {
  const { errors, config, setConfig, hightouchColumns, destination, loadingModel, reloadModel } = useDestinationForm();

  const {
    data: columnsData,
    error: columnsError,
    isFetching: loadingColumns,
    refetch: getColumns,
  } = useIterableColumnsQuery({
    destinationId: String(destination?.id),
  });

  const columns = columnsData?.iterableUserFields?.fields;

  const columnOpts =
    columns?.map((field) => ({
      label: field.name,
      value: field.name,
      type: field.standardType,
    })) || [];

  const {
    data: campaignsData,
    error: campaignsError,
    isFetching: loadingCampaigns,
    refetch: getCampaigns,
  } = useIterableCampaignsQuery({
    destinationId: String(destination?.id),
  });
  const campaigns = campaignsData?.iterableGetCampaigns?.campaigns;
  const campaignOpts =
    campaigns?.map((campaign) => ({
      label: campaign.name,
      value: campaign.id,
    })) || [];

  const {
    data: templatesData,
    error: templatesError,
    isFetching: loadingTemplates,
    refetch: getTemplates,
  } = useIterableTemplatesQuery({
    destinationId: String(destination?.id),
  });
  const templates = templatesData?.iterableGetTemplates?.templates;
  const templateOpts =
    templates?.map((template) => ({
      label: template.name,
      value: template.id,
    })) || [];

  const {
    data: catalogsData,
    error: catalogsError,
    isFetching: loadingCatalogs,
    refetch: getCatalogs,
  } = useIterableGetCatalogsQuery({
    destinationId: String(destination?.id),
  });
  const catalogs = catalogsData?.iterableGetCatalogs?.catalogs;
  const catalogOpts =
    catalogs?.map((catalog) => ({
      label: catalog?.name,
      value: catalog?.id,
    })) || [];
  const {
    data: catalogFieldsData,
    isFetching: loadingCatalogFields,
    refetch: getCatalogFields,
  } = useIterableCatalogFieldsQuery({
    destinationId: String(destination?.id),
    catalogName: config?.object,
  });
  const catalogFields = catalogFieldsData?.iterableGetCatalogFields?.catalogFields;
  const catalogFieldOpts =
    catalogFields?.map((catalog) => ({
      label: catalog.name,
      value: catalog.id,
      type: catalog.type,
    })) || [];
  return (
    <>
      <TypeField error={errors?.type} options={TYPES} />

      {/* Value not used - just here to make clear to user what object they are syncing */}
      {config?.type === "object" && <ObjectField options={[{ label: "User", value: "user" }]} value={"user"} />}

      {config?.type && (
        <ModeField
          error={errors?.mode}
          options={MODES_BY_TYPE?.[config?.type]}
          onChange={(mode) => {
            setConfig({ ...config, mode });
          }}
        />
      )}

      {/* Object (User) fields */}
      {config?.mode && config?.type === "object" && (
        <>
          <Section>
            <Grid gap={8}>
              <IdMappingField options={config?.mode === "upsert" ? OBJECT_UPSERT_FIELDS : OBJECT_EXTERNAL_FIELDS} />

              {config?.mode === "upsert" && (
                <Field optional error={errors?.userIdFrom} label="Which column contains the Iterable User ID?">
                  <Select
                    isClearable
                    isError={errors?.userIdFrom}
                    isLoading={loadingModel}
                    options={hightouchColumns}
                    placeholder="Select a column..."
                    reload={reloadModel}
                    value={config?.userIdFrom}
                    width="240px"
                    onChange={(selected) => {
                      const val = selected?.value;
                      setConfig({ ...config, userIdFrom: val });
                    }}
                  />
                </Field>
              )}
            </Grid>
          </Section>
          <Section>
            <MappingsField
              isCreatable
              error={columnsError?.message}
              loading={loadingColumns}
              options={columnOpts}
              reload={getColumns}
            />
          </Section>
          <Section>
            <Field
              description={`By default, Hightouch will update all mapped fields for a user when a row has changed. If you want to optimize your data point usage, switch to the slower mode which only updates the changed fields when a row has changed.`}
              label="Would you like Hightouch to only update fields that were changed?"
              size="large"
            >
              <RadioGroup
                options={[
                  { label: "Update all fields when a row has changed (faster)", value: undefined },
                  {
                    label: "Only update changed fields when a row has changed (slower)",
                    description:
                      "This option will significantly reduce the speed of your sync due to additional non-bulk API calls to Iterable.",
                    value: true,
                  },
                ]}
                value={config?.partialUpdates}
                onChange={(partialUpdates) => setConfig({ ...config, partialUpdates })}
              />
            </Field>
          </Section>

          <DeleteField modes={config?.mode === "upsert" ? ["clear", "delete"] : ["clear"]} />
        </>
      )}

      {/* Event fields */}
      {config?.mode && config?.type === "event" && (
        <>
          <Section>
            <FromIdField fieldName="Event ID" />
          </Section>
          <Section>
            <Grid gap={8}>
              <Field
                description={"Mapping an event name column in the field mappings will overwrite this value."}
                error={errors?.eventName}
                label="What is the event name?"
              >
                <Input
                  defaultValue={config?.eventName}
                  error={errors?.eventName}
                  placeholder="Enter event name..."
                  sx={{ width: "240px" }}
                  onChange={(value) => {
                    setConfig({
                      ...config,
                      eventName: value,
                    });
                  }}
                />
              </Field>
              <Field
                description="Either email or user ID must be specified. If both present, email takes precedence."
                error={errors?.emailFrom}
                label="Which column contains the user email?"
              >
                <Select
                  isClearable
                  isError={errors?.emailFrom}
                  isLoading={loadingModel}
                  options={hightouchColumns}
                  placeholder="Select a column..."
                  reload={reloadModel}
                  value={config?.emailFrom}
                  width="240px"
                  onChange={(selected) => {
                    const val = selected?.value;
                    setConfig({ ...config, emailFrom: val });
                  }}
                />
              </Field>
              <Field
                description="Either email or user ID must be specified. If both present, email takes precedence."
                error={errors?.userIdFrom}
                label="Which column contains the user ID?"
              >
                <Select
                  isClearable
                  isError={errors?.userIdFrom}
                  isLoading={loadingModel}
                  options={hightouchColumns}
                  placeholder="Select a column..."
                  reload={reloadModel}
                  value={config?.userIdFrom}
                  width="240px"
                  onChange={(selected) => {
                    const val = selected?.value;
                    setConfig({ ...config, userIdFrom: val });
                  }}
                />
              </Field>
              <Field
                optional
                description="If not specified, Iterable will use the time it receives the event."
                error={errors?.timestampFrom}
                label="Which column contains the event timestamp?"
              >
                <Select
                  isClearable
                  isError={errors?.timestampFrom}
                  isLoading={loadingModel}
                  options={hightouchColumns}
                  placeholder="Select a column..."
                  reload={reloadModel}
                  value={config?.timestampFrom}
                  width="240px"
                  onChange={(selected) => {
                    const val = selected?.value;
                    setConfig({ ...config, timestampFrom: val });
                  }}
                />
              </Field>
              <Field
                optional
                description="Mapping a campaign ID column in the field mappings will overwrite this value."
                error={campaignsError?.message}
                label="What is the campaign tied to the event?"
              >
                <Select
                  isClearable
                  isError={errors?.campaignId}
                  isLoading={loadingCampaigns}
                  options={campaignOpts}
                  placeholder="Select a campaign..."
                  reload={getCampaigns}
                  value={campaignOpts?.find((o) => o.value === config?.campaignId) || null}
                  width="240px"
                  onChange={(selected) => {
                    const val = selected?.value;
                    setConfig({ ...config, campaignId: val });
                  }}
                />
              </Field>
              <Field
                optional
                description="Mapping a template ID column in the field mappings will overwrite this value."
                error={templatesError?.message}
                label="What is the template?"
              >
                <Select
                  isClearable
                  isError={errors?.templateId}
                  isLoading={loadingTemplates}
                  options={templateOpts}
                  placeholder="Select a template..."
                  reload={getTemplates}
                  value={templateOpts?.find((o) => o.value === config?.templateId) || null}
                  width="240px"
                  onChange={(selected) => {
                    const val = selected?.value;
                    setConfig({ ...config, templateId: val });
                  }}
                />
              </Field>
            </Grid>
          </Section>

          <Section>
            <MappingsField
              error={errors?.mappings}
              loading={loadingModel}
              options={EVENT_EXTERNAL_FIELDS}
              reload={reloadModel}
            />
          </Section>
          <Section>
            <MappingsField isCustom />
          </Section>
        </>
      )}

      {config?.type === "catalog" && (
        <Section>
          <Grid gap={8}>
            <Field
              optional
              description="Select which Iterable catalog you'd like to sync to."
              error={catalogsError?.message}
              label="Select your product catalog"
            >
              <Select
                isClearable
                isError={errors?.catalogsError}
                isLoading={loadingCatalogs}
                options={catalogOpts}
                placeholder="Select a catalog..."
                reload={getCatalogs}
                value={catalogOpts?.find((o) => o.value === config?.object) || null}
                width="240px"
                onChange={(selected) => {
                  const val = selected?.value;
                  setConfig({ ...config, object: val });
                }}
              />
            </Field>
            <IdMappingField options={CATALOG_ID_FIELDS} />

            <MappingsField
              error={errors?.catalogFieldsError}
              loading={loadingCatalogFields}
              options={catalogFieldOpts}
              reload={getCatalogFields}
            />
            <DeleteField modes={["delete"]} />
          </Grid>
        </Section>
      )}
      {config?.type && config?.mode && config?.type !== "catalog" && (
        <Section>
          <Grid gap={8}>
            <Field
              optional
              description="Defaults to 1000 rows per batch. This is helpful when running into file too large errors."
              label="How many rows would you like to send per batch?"
              size="large"
            >
              <Input
                defaultValue={config?.rowsPerBatch}
                max="1000"
                placeholder={"1000"}
                sx={{ width: "180px" }}
                type="number"
                onChange={(rowsPerBatch) =>
                  setConfig({ ...config, rowsPerBatch: rowsPerBatch ? Number(rowsPerBatch) : undefined })
                }
              />
            </Field>
          </Grid>
        </Section>
      )}
    </>
  );
};

export default { form: IterableForm, validation };
