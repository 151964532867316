import { createContext, useContext, FC } from "react";

import { FormkitContextType, useFormkitContext } from "src/formkit/components/formkit-context";

type Props = {
  config: any;
  errors: any;
  setConfig: (config: any) => void;
  setCustomValidation: (object: { validate: (config: any) => Promise<{ yupError?: any; otherError?: any }> }) => void;
  setErrors: (errors: any) => void;
};

export type DestinationFormContextType = Props &
  Omit<FormkitContextType, "columns" | "sourceId" | "workspaceId" | "isSetup"> & { hightouchColumns: any };

export const DestinationFormContext = createContext<DestinationFormContextType>({} as DestinationFormContextType);

export const useDestinationForm = () => useContext(DestinationFormContext);

export const DestinationFormProvider: FC<Readonly<Props>> = ({
  children,
  config,
  errors,
  setConfig,
  setCustomValidation,
  setErrors,
}) => {
  const {
    columns,
    destination,
    destinationDefinition,
    loadingModel,
    loadingRows,
    model,
    reloadModel,
    reloadRows,
    rows,
    slug,
    sourceDefinition,
    sync,
    validate,
  } = useFormkitContext();

  return (
    <DestinationFormContext.Provider
      value={{
        config,
        destination,
        destinationDefinition,
        errors,
        hightouchColumns: columns,
        loadingModel,
        loadingRows,
        model,
        reloadModel,
        reloadRows,
        rows,
        setConfig,
        setCustomValidation,
        setErrors,
        slug,
        sourceDefinition,
        sync,
        validate,
      }}
    >
      {children}
    </DestinationFormContext.Provider>
  );
};
