import { FC } from "react";

import { Grid } from "theme-ui";
import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { RadioGroup } from "src/ui/radio";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { COMMON_SCHEMAS, StandardFieldType } from "src/utils/destinations";

import { ColumnOrConstantField } from "../column-or-constant-field";
import { IdMappingField } from "../id-mapping-field";
import { MappingsField } from "../mappings-field";
import { ModeField } from "../mode-field";
import { ObjectField } from "../object-field";
import { TypeField } from "../type-field";

export const validation = Yup.object().shape({
  type: Yup.string().required().default("object"),
  object: Yup.string().when("type", {
    is: "object",
    then: Yup.string().required().default("user"),
    otherwise: Yup.string().notRequired(),
  }),
  mode: Yup.string().when("type", {
    is: "object",
    then: Yup.string().required().default("upsert"),
    otherwise: Yup.string().notRequired(),
  }),
  eventName: Yup.mixed().when("type", {
    is: "event",
    then: Yup.mixed().when("eventType", {
      is: "purchase",
      then: Yup.string().notRequired(),
      otherwise: COMMON_SCHEMAS.columnOrConstant,
    }),
    otherwise: Yup.string().notRequired(),
  }),
  eventType: Yup.string().notRequired(),
  timestampFrom: Yup.mixed().when("mode", {
    is: "event",
    then: Yup.mixed().required(),
    otherwise: Yup.mixed().notRequired(),
  }),
  updateExistingOnly: Yup.boolean().notRequired(),
  externalIdMapping: COMMON_SCHEMAS.externalIdMapping,
  mappings: COMMON_SCHEMAS.mappings,
  customMappings: COMMON_SCHEMAS.mappings,
  customSegmentName: Yup.string().notRequired(),
});

const EXTERNAL_FIELDS = [
  { label: "Braze External ID", value: "external_id" },
  { label: "Braze ID", value: "braze_id" },
  { label: "User Alias Object", value: "user_alias" },
];

const UPSERT_FIELDS = [
  { label: "Braze External ID", value: "external_id" },
  { label: "User Alias Object", value: "user_alias" },
];

const PURCHASE_FIELDS = [
  { label: "product_id", value: "product_id", type: StandardFieldType.STRING, required: true },
  { label: "currency", value: "currency", type: StandardFieldType.STRING, required: true },
  { label: "price", value: "price", type: StandardFieldType.NUMBER, required: true },
  { label: "quantity", value: "quantity", type: StandardFieldType.NUMBER },
  { label: "app_id", value: "app_id", type: StandardFieldType.STRING },
];

const EVENT_FIELDS = [{ label: "app_id", value: "app_id", type: StandardFieldType.STRING }];

const FIELDS = [
  { label: "country", value: "country", type: StandardFieldType.STRING },
  { label: "current_location", value: "current_location", type: StandardFieldType.OBJECT },
  { label: "date_of_first_session", value: "date_of_first_session", type: StandardFieldType.DATETIME },
  { label: "date_of_last_session", value: "date_of_last_session", type: StandardFieldType.DATETIME },
  { label: "dob", value: "dob", type: StandardFieldType.STRING },
  { label: "email", value: "email", type: StandardFieldType.EMAIL },
  { label: "email_subscribe", value: "email_subscribe", type: StandardFieldType.STRING },
  { label: "facebook", value: "facebook", type: StandardFieldType.STRING },
  { label: "first_name", value: "first_name", type: StandardFieldType.STRING },
  { label: "gender", value: "gender", type: StandardFieldType.STRING },
  { label: "home_city", value: "home_city", type: StandardFieldType.STRING },
  { label: "image_url", value: "image_url", type: StandardFieldType.STRING },
  { label: "language", value: "language", type: StandardFieldType.STRING },
  { label: "last_name", value: "last_name", type: StandardFieldType.STRING },
  { label: "marked_email_as_spam_at", value: "marked_email_as_spam_at", type: StandardFieldType.DATETIME },
  { label: "phone", value: "phone", type: StandardFieldType.STRING },
  { label: "push_subscribe", value: "push_subscribe", type: StandardFieldType.STRING },
  { label: "push_tokens", value: "push_tokens", type: StandardFieldType.ARRAY },
  { label: "time_zone", value: "time_zone", type: StandardFieldType.STRING },
  { label: "twitter", value: "twitter", type: StandardFieldType.STRING },
];

const OBJECTS = [{ label: "User", value: "user" }];

const OBJECT_MODES = [
  { label: "Upsert", value: "upsert" },
  { label: "Update", value: "update" },
];

const EVENT_MODES = [{ label: "Insert", value: "insert" }];

export const BrazeForm: FC = () => {
  const { hightouchColumns, config, setConfig, errors } = useDestinationForm();

  const TYPES = [
    { label: "Object", value: "object" },
    { label: "Event", value: "event" },
  ];

  return (
    <>
      <TypeField
        options={TYPES}
        value={config?.type}
        onChange={(type) => {
          setConfig({
            type,
          });
        }}
      />

      {config?.type === "object" && (
        <ObjectField options={OBJECTS} onChange={(object) => setConfig({ type: config?.type, object })} />
      )}

      {config?.object && (
        <ModeField
          options={OBJECT_MODES}
          onChange={(mode) => {
            setConfig({ type: config?.type, object: config?.object, mode });
          }}
        />
      )}

      {config?.type === "event" && <ModeField options={EVENT_MODES} value={"insert"} />}

      {config?.type === "event" && (
        <Section>
          <Grid gap={8}>
            <Field error={errors?.eventType} label="What is the event type?" size="large">
              <RadioGroup
                options={[
                  { label: "Track Event", value: undefined },
                  { label: "Track Purchase", value: "purchase" },
                ]}
                value={config?.eventType}
                onChange={(eventType) => {
                  setConfig({ type: config?.type, eventType });
                }}
              />
            </Field>
            <Field
              description={"This option decides the behavior when an event is sent where the user does not exist."}
              error={errors?.updateExistingOnly}
              label="Would you like to create users that don't exist?"
              size="large"
            >
              <RadioGroup
                options={[
                  { label: "Create users that don't exist", value: undefined },
                  { label: "Only track events for users that already exist", value: true },
                ]}
                value={config?.updateExistingOnly}
                onChange={(updateExistingOnly) => {
                  setConfig({ type: config?.type, eventType: config?.eventType, updateExistingOnly });
                }}
              />
            </Field>
          </Grid>
        </Section>
      )}

      {(config?.type === "event" || config?.object) && (
        <Section>
          <Grid gap={8}>
            {config?.type === "event" && (
              <>
                {config?.eventType !== "purchase" && (
                  <ColumnOrConstantField
                    columnLabel="Which column contains the event name?"
                    constantInput={
                      <Input
                        defaultValue={config?.eventName}
                        error={errors?.eventName}
                        placeholder="Enter event name..."
                        sx={{ width: "340px" }}
                        onChange={(value) => {
                          setConfig({
                            ...config,
                            eventName: value,
                          });
                        }}
                      />
                    }
                    constantLabel="What is the event name?"
                    property="eventName"
                  />
                )}
                <Field error={errors?.timestampFrom} label="Which column contains the event timestamp?" size="large">
                  <Select
                    isError={errors?.timestampFrom}
                    options={hightouchColumns}
                    placeholder="Select a column..."
                    value={config?.timestampFrom}
                    width="340px"
                    onChange={(selected) => {
                      const val = selected?.value;
                      setConfig({ ...config, timestampFrom: val });
                    }}
                  />
                </Field>
              </>
            )}
            <IdMappingField
              options={
                config?.mode === "upsert" || (config?.type === "event" && !config?.updateExistingOnly)
                  ? UPSERT_FIELDS
                  : EXTERNAL_FIELDS
              }
              path={[config?.type]}
            />
          </Grid>
        </Section>
      )}

      {(config?.object || config?.type === "event") && (
        <>
          <Section>
            <MappingsField
              options={config?.object ? FIELDS : config?.eventType === "purchase" ? PURCHASE_FIELDS : EVENT_FIELDS}
            />
          </Section>
          <Section>
            <MappingsField isCustom />
          </Section>
        </>
      )}
    </>
  );
};

export default { form: BrazeForm, validation };
