import { forwardRef } from "react";

import Flag from "react-world-flags";
import { Text } from "theme-ui";

import { Row } from "../../ui/box";
import { NewSelect } from "../../ui/new-select";

export const RegionOption = ({ friendlyName, internalName, flagCode }) => {
  return (
    <Row sx={{ whiteSpace: "nowrap" }}>
      <Flag code={flagCode} width={"26px"} />
      <Text sx={{ ml: 4, fontWeight: "bold" }}>{friendlyName}</Text>
      <Text color={"base.5"} sx={{ ml: 2 }}>
        {internalName}
      </Text>
    </Row>
  );
};

const regionOptions = [
  {
    value: "us-east-1",
    render: () => <RegionOption flagCode={"us"} friendlyName={"N. Virginia"} internalName={"us-east-1"} />,
  },
  {
    value: "eu-west-1",
    render: () => <RegionOption flagCode={"ie"} friendlyName={"Ireland"} internalName={"eu-west-1"} />,
  },
];

export const DisplayRegion = ({ region }) => {
  return (
    <Row
      sx={{
        position: "relative",
        alignItems: "center",
        pl: 3,
        pr: 9,
        minWidth: "100px",
        border: "small",
        borderColor: "base.2",
        borderRadius: 1,
        backgroundColor: "base.0",
        height: "32px",
        fontSize: 0,
        justifyContent: "space-between",
      }}
    >
      {regionOptions.find(({ value }) => value == region)?.render()}
    </Row>
  );
};

type Props = {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
};

export const SelectRegion = forwardRef<HTMLDivElement, Props>(({ value, onChange, disabled = false }, _ref) => {
  return <NewSelect disabled={disabled} options={regionOptions} value={value} onChange={onChange} />;
});

SelectRegion.displayName = "SelectRegion";
