import { useEffect, useState, VFC } from "react";

import { groupBy } from "lodash";
import pluralize from "pluralize";
import { useQueryClient } from "react-query";
import { useToasts } from "react-toast-notifications";
import { Flex, Grid, Text } from "theme-ui";

import { PlanWelcomeModal } from "src/components/modals/plan-welcome-modal";
import { PricingModal } from "src/components/modals/pricing-modal";
import { PricingPlan, pricingPlans } from "src/components/pricing/pricing-display";
import { Settings } from "src/components/settings";
import { DestinationTiles, freeDestinations, premiumDestinations } from "src/components/settings/destination-tiles";
import { FeatureCatalog } from "src/components/settings/feature-catalog";
import { WorkspaceMetricsTable, WorkspaceMetrics } from "src/components/settings/workspace-metrics-table";
import { useUser } from "src/contexts/user-context";
import {
  useBillingOrganizationQuery,
  useDestinationDefinitionsQuery,
  useOrganizationDestinationTypesQuery,
  useOrganizationMetricsQuery,
  usePortalUrlQuery,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Badge } from "src/ui/badge";
import { Box, Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Heading } from "src/ui/heading";
import { WarningIcon } from "src/ui/icons";
import { Label } from "src/ui/label";
import { Link } from "src/ui/link";
import { PageSpinner, Spinner } from "src/ui/loading";
import { Modal } from "src/ui/modal";
import { Tooltip } from "src/ui/tooltip";
import { commaNumber } from "src/utils/numbers";
import { useQueryString } from "src/utils/use-query-string";

export const Billing: VFC = () => {
  const { workspace } = useUser();
  const client = useQueryClient();
  const { addToast } = useToasts();
  const { data: orgData, isLoading: loadingOrg } = useBillingOrganizationQuery();
  const { data: organizationMetrics, isLoading: loadingOrgUsage } = useOrganizationMetricsQuery();
  const { data: organizationDestinationType, isLoading: loadingDestinationTypes } = useOrganizationDestinationTypesQuery();
  const { data: destinationDefinitions, isLoading: definitionsLoading } = useDestinationDefinitionsQuery();
  const [showWorkspaces, setShowWorkspaces] = useState(false);
  const [showDestinations, setShowDestinations] = useState(false);
  const [loadingStripe, setLoadingStripe] = useState(false);
  const {
    data: { success, canceled },
  } = useQueryString();
  const [successfulCheckoutPlan, setSuccessfulCheckoutPlan] = useState<PricingPlan | undefined>();
  const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);
  useEffect(() => {
    if (success) {
      const plan = pricingPlans.find((pricingPlans) => pricingPlans.sku === success);
      if (plan) {
        analytics.track("Checkout Successful", {
          workspace_id: workspace?.id,
          sku: plan.sku,
        });
        setSuccessfulCheckoutPlan(plan);
      }
    }
  }, [success]);

  useEffect(() => {
    if (canceled) {
      analytics.track("Checkout canceled", {
        workspace_id: workspace?.id,
      });
    }
  }, [canceled]);

  useEffect(() => {
    analytics.track("Billing Screen Viewed", {
      workspace_id: workspace?.id,
    });
  }, []);

  const organization = orgData?.getBillingOrganization;
  const entitlements = {
    destinations: 1,
    preimumDestinations: 0,
    ...organization?.plan?.entitlements,
    ...organization?.entitlements,
  };

  const usageMetrics: WorkspaceMetrics[] = organizationMetrics?.getOrganizationMetrics?.metrics || [];
  const rolesByWorkspaceId = groupBy(organizationMetrics?.getOrganizationMetrics?.roles, "workspace_id");
  let billableDistinations = 0;
  let freeDistinations = 0;
  let totalDestinations = 0;
  let preimumDestinations = 0;
  organizationDestinationType?.getOrganizationDestinationTypes.forEach((dest) => {
    if (!dest) {
      return;
    }

    if (freeDestinations.includes(dest.type)) {
      freeDistinations += 1;
    } else {
      billableDistinations += 1;
    }
    if (premiumDestinations.includes(dest.type)) {
      preimumDestinations += 1;
    }
    totalDestinations += 1;
  });

  const destinationOverage = billableDistinations > entitlements?.destinations && entitlements?.destinations !== -1;
  const premiumOverage = preimumDestinations > entitlements?.preimumDestinations;
  const overage = destinationOverage || premiumOverage;

  usageMetrics.forEach((workspace) => {
    workspace.roles = rolesByWorkspaceId[workspace.id];
  });

  const openPricingModal = (source: string) => () => {
    setIsPricingModalOpen(true);
    analytics.track("Upgrade Plan Clicked", {
      workspace_id: workspace?.id,
      source,
    });
  };

  const goToPortal = async () => {
    if (!workspace) {
      return;
    }

    if (organization?.billing?.customer_id) {
      setLoadingStripe(true);
      analytics.track("Manage Plan Clicked", {
        workspace_id: workspace.id,
      });
      try {
        const { getPortalUrl } = await client.fetchQuery(
          usePortalUrlQuery.getKey({ customer_id: workspace.organization?.billing?.customer_id }),
          usePortalUrlQuery.fetcher({ customer_id: workspace.organization?.billing?.customer_id }),
        );
        if (getPortalUrl.redirect) {
          location.href = getPortalUrl.redirect;
        } else {
          addToast("Can not generate portal url", { appearance: "error", autoDismiss: false });
          setLoadingStripe(false);
        }
      } catch (e) {
        addToast(e.message, { appearance: "error", autoDismiss: false });
        setLoadingStripe(false);
      }
    }
  };

  if (loadingOrg) {
    return <PageSpinner />;
  }

  return (
    <Settings route="billing">
      <Grid gap={12}>
        {overage && (
          <Grid gap={4}>
            {destinationOverage && (
              <Box>
                <Flex
                  sx={{
                    borderRadius: 1,
                    bg: "reds.0",
                    border: "small",
                    borderColor: "red",
                    position: "relative",
                    alignItems: "center",
                    p: 4,
                  }}
                >
                  <WarningIcon color="red" sx={{ pr: 4 }} />
                  <Box>
                    <Text sx={{ display: "inline", fontWeight: 600 }}>Destination limit exceeded:</Text> You've exceeded the
                    number of destinations allowed on your current plan. Please{" "}
                    <Link
                      sx={{ color: "black", textDecoration: "underline" }}
                      onClick={openPricingModal("destinations_banner")}
                    >
                      upgrade your plan
                    </Link>
                    .
                  </Box>
                </Flex>
              </Box>
            )}
            {premiumOverage && (
              <Box>
                <Flex
                  sx={{
                    borderRadius: 1,
                    bg: "reds.0",
                    border: "small",
                    borderColor: "red",
                    position: "relative",
                    alignItems: "center",
                    p: 4,
                  }}
                >
                  <WarningIcon color="red" sx={{ pr: 4 }} />
                  <Box>
                    <Text sx={{ display: "inline", fontWeight: 600 }}>Premium Destination used:</Text> You've used a premium
                    destination and need to{" "}
                    <Link sx={{ color: "black", textDecoration: "underline" }} onClick={openPricingModal("premium_banner")}>
                      upgrade your plan
                    </Link>
                    .
                  </Box>
                </Flex>
              </Box>
            )}
          </Grid>
        )}
        <Box>
          <Label size="large" sx={{ marginBottom: 0 }}>
            Overview {organization ? ` For ${organization.name}` : ""}
          </Label>
          <Row gap={8} sx={{ height: "88px", display: "flex", alignSelf: "flex-start", alignItems: "flex-start", mb: 8 }}>
            <Column sx={{ flex: 1, height: "100%" }}>
              <Text sx={{ fontSize: 0, textTransform: "uppercase", color: "base.4", fontWeight: "bold", mb: 4 }}>Plan</Text>
              <Heading sx={{ mb: 4 }} variant="h3">
                {organization?.plan?.name || "Free"}
              </Heading>
              {organization?.plan?.sku !== "business_tier" &&
                (organization?.billing?.customer_id ? (
                  <Row sx={{}}>
                    <Link
                      sx={{ fontSize: "12px", pr: 6 }}
                      onClick={() => {
                        goToPortal();
                      }}
                    >
                      Manage {loadingStripe && <Spinner sx={{ display: "inline", width: 16 }} />}
                    </Link>
                    <Link sx={{ fontSize: "12px", borderLeft: "small", pl: 6 }} onClick={openPricingModal("existing_plan")}>
                      Upgrade
                    </Link>
                  </Row>
                ) : (
                  <Link sx={{ fontSize: "12px" }} onClick={openPricingModal("kpi_bar")}>
                    Upgrade Plan
                  </Link>
                ))}
            </Column>
            <Column sx={{ pl: 10, borderLeft: "small", flex: 1, height: "100%" }}>
              <Text sx={{ fontSize: 0, textTransform: "uppercase", color: "base.4", fontWeight: "bold", mb: 4 }}>
                Billable Destinations
              </Text>
              <Heading sx={{ mb: 4, position: "relative" }} variant="h3">
                {billableDistinations} of {entitlements?.destinations === -1 ? <span>&infin;</span> : entitlements.destinations}
                {overage && (
                  <Badge sx={{ position: "absolute", top: "-2px", right: -3 }} variant="red">
                    Limit Exceeded
                  </Badge>
                )}
              </Heading>
              <Link sx={{ fontSize: "12px" }} onClick={() => setShowDestinations(true)}>
                View All Destinations
              </Link>
            </Column>
            <Column sx={{ pl: 10, borderLeft: "small", flex: 1, height: "100%" }}>
              <Text sx={{ fontSize: 0, textTransform: "uppercase", color: "base.4", fontWeight: "bold", mb: 4 }}>Cost</Text>
              <Heading sx={{ mb: 4 }} variant="h3">
                {organization?.plan?.sku === "business_tier"
                  ? "Contract"
                  : `$${
                      organization?.billing?.subscription_cost
                        ? `${commaNumber(organization?.billing?.subscription_cost)} / ${
                            workspace!.organization?.billing?.subscription_interval
                          }`
                        : "0"
                    }`}
              </Heading>
            </Column>
            <Column sx={{ pl: 10, borderLeft: "small", flex: 1, height: "100%" }}>
              <Text sx={{ fontSize: 0, textTransform: "uppercase", color: "base.4", fontWeight: "bold", mb: 4 }}>
                Workspaces
              </Text>
              <Heading sx={{ mb: 4 }} variant="h3">
                {usageMetrics.length}
              </Heading>
              <Link sx={{ fontSize: "12px" }} onClick={() => setShowWorkspaces(true)}>
                View All Workspaces
              </Link>
            </Column>
          </Row>
        </Box>

        <Modal
          footer={<Button onClick={() => setShowWorkspaces(false)}>Close</Button>}
          isOpen={showWorkspaces}
          sx={{ width: "1080px" }}
          title="Workspaces"
          onClose={() => setShowWorkspaces(false)}
        >
          <WorkspaceMetricsTable isLoading={loadingOrgUsage} metrics={usageMetrics} />
        </Modal>

        <Modal
          footer={<Button onClick={() => setShowDestinations(false)}>Close</Button>}
          isOpen={showDestinations}
          sx={{ width: "1080px" }}
          title="Destinations"
          onClose={() => setShowDestinations(false)}
        >
          <Grid gap={6}>
            <Box>
              <Flex
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  borderRadius: 1,
                  px: 3,
                  py: 3,
                  bg: "white",
                  border: "small",
                  borderColor: undefined,
                  position: "relative",
                  fontSize: 2,
                }}
              >
                <Row sx={{ pr: 15, height: "100%" }}>
                  <Text sx={{ fontWeight: "bold", pr: 3 }}>Plan limit:</Text>
                  <Text>
                    {entitlements?.destinations === -1 ? <span>&infin;</span> : entitlements?.destinations || 1}{" "}
                    {pluralize("destination", entitlements?.destinations || 1)}
                  </Text>
                </Row>
                <Row sx={{ pl: 5, pr: 5, borderLeft: "small", height: "100%" }}>
                  <Text sx={{ fontWeight: "bold", pr: 3 }}>Current usage:</Text>
                  <Text sx={{ color: overage ? "red" : "", display: "flex" }}>
                    {billableDistinations} {pluralize("destination", billableDistinations)}
                    {overage && (
                      <Tooltip
                        sx={{ display: "flex" }}
                        text={`You are using ${billableDistinations} billable ${pluralize(
                          "destination",
                          billableDistinations,
                        )}${
                          preimumDestinations > 0
                            ? `, including ${preimumDestinations} premium ${pluralize("destination", preimumDestinations)},`
                            : ""
                        } of the ${entitlements.destinations} included in your plan.`}
                      >
                        <WarningIcon color="red" sx={{ pl: 2 }} />
                      </Tooltip>
                    )}
                  </Text>
                </Row>
              </Flex>
            </Box>
            <Box>
              <Flex
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  borderRadius: 1,
                  px: 3,
                  py: 3,
                  bg: "white",
                  border: "small",
                  borderColor: undefined,
                  position: "relative",
                }}
              >
                <Column sx={{ pr: 5, height: "100%" }}>
                  <Text sx={{ fontSize: 0, textTransform: "uppercase", color: "base.4", fontWeight: "bold", mb: 4 }}>
                    Billable Destinations
                  </Text>
                  <Heading sx={{ pb: 2, pt: 0 }} variant="h3">
                    {billableDistinations}
                  </Heading>
                </Column>
                <Column sx={{ pl: 5, pr: 5, borderLeft: "small", height: "100%" }}>
                  <Text sx={{ fontSize: 0, textTransform: "uppercase", color: "base.4", fontWeight: "bold", mb: 4 }}>
                    Free Destinations
                  </Text>
                  <Heading sx={{ pb: 2, pt: 0 }} variant="h3">
                    {freeDistinations}
                  </Heading>
                </Column>
                <Column sx={{ pl: 5, pr: 5, borderLeft: "small", height: "100%" }}>
                  <Text sx={{ fontSize: 0, textTransform: "uppercase", color: "base.4", fontWeight: "bold", mb: 4 }}>
                    Total Count
                  </Text>
                  <Heading sx={{ pb: 2, pt: 0 }} variant="h3">
                    {totalDestinations}
                  </Heading>
                </Column>
              </Flex>
            </Box>
            <DestinationTiles
              definitions={destinationDefinitions}
              destinationTypes={organizationDestinationType}
              isLoadingDefinitions={definitionsLoading}
              isLoadingDestinationTypes={loadingDestinationTypes}
            />
          </Grid>
        </Modal>

        <Box>
          <Label size="large" sx={{ marginBottom: 0 }}>
            Plan Features
          </Label>
          <FeatureCatalog />
        </Box>
      </Grid>
      {successfulCheckoutPlan && (
        <PlanWelcomeModal
          checkList={successfulCheckoutPlan.checkList}
          isOpen={Boolean(successfulCheckoutPlan)}
          plan={successfulCheckoutPlan.plan}
          onClose={() => {
            setSuccessfulCheckoutPlan(undefined);
          }}
        />
      )}

      {isPricingModalOpen && (
        <PricingModal
          isOpen={isPricingModalOpen}
          organization={organization}
          onClose={() => {
            analytics.track("Pricing Modal Dismissed", {
              workspace_id: workspace?.id,
            });
            setIsPricingModalOpen(false);
          }}
        />
      )}
    </Settings>
  );
};
