import { FC } from "react";

import { Text } from "theme-ui";

import { Editor } from "src/components/editor";
import * as SyncErrors from "src/types/sync-errors";
import { SyncRequestErrorCode } from "src/types/sync-errors";
import { Row, Container } from "src/ui/box";
import { Link } from "src/ui/link";
import { Modal } from "src/ui/modal";
import { SyncStatus } from "src/utils/syncs";

type SyncRequestErrorModalProps<syncRequestError = SyncErrors.SyncRequestErrorInfo> = {
  isOpen: boolean;
  onClose: () => void;
  syncRequestError: syncRequestError | undefined;
  syncStatus: SyncStatus | undefined;
};

const oneOrMore = (singular: string, plural: string, c: number) => {
  return c > 1 ? plural : singular;
};

const maxWidth = "800px";

const NonUniquePrimaryKeyModal: FC<Readonly<SyncRequestErrorModalProps>> = ({ isOpen, onClose, syncRequestError }) => {
  const errInfo = syncRequestError as SyncErrors.NonUniquePrimaryKeyErrorInfo;
  return (
    <Modal info isOpen={isOpen} title={"Primary key is not unique"} onClose={onClose}>
      <Container sx={{ maxWidth }}>
        <Row>
          <Text>Warehouse Planning requires that every row in your Model have a&nbsp;</Text>
          <Link newTab to={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-planning/#primary-key-is-not-unique`}>
            unique primary key value
          </Link>
          <Text>.</Text>
        </Row>
        <Row sx={{ mt: 2 }}>
          <Text>You can run the following query to identify the duplicate records.</Text>
        </Row>
        <Row>
          {/* TODO button to copy */}
          <Editor readOnly language="sql" value={errInfo?.nonUniquePrimaryKeyInfo?.sqlToIdentifyDuplicateRows} />
        </Row>
      </Container>
    </Modal>
  );
};

const PreviousSyncRunObjectMissingModal: FC<Readonly<SyncRequestErrorModalProps>> = ({ isOpen, onClose }) => {
  return (
    <Modal info isOpen={isOpen} title={"Full resync needed"} onClose={onClose}>
      <Container sx={{ maxWidth }}>
        <Row>
          <Text>
            {"Previous sync state required for diffing is missing." +
              " This is usually because your sync hasn't run in more than 30 days, so your sync data has been purged from our storage."}
          </Text>
        </Row>
        <Row sx={{ mt: 2 }}>
          <Text>Trigger a&nbsp;</Text>
          <Link newTab to={`${import.meta.env.VITE_DOCS_URL}/syncs/overview/#resync-full-query`}>
            full resync
          </Link>
          <Text>
            &nbsp;to restart the sync without diffing. This will cause the sync to re-run as if it were newly created.
          </Text>
        </Row>
        <Row sx={{ mt: 2 }}>
          <Text>Check out&nbsp;</Text>
          <Link newTab to={`${import.meta.env.VITE_DOCS_URL}/security/storage/#expiration`}>
            our docs
          </Link>
          <Text>&nbsp;for more information.</Text>
        </Row>
      </Container>
    </Modal>
  );
};

const RemoveRetryChangedColumnTypesModal: FC<Readonly<SyncRequestErrorModalProps>> = ({
  isOpen,
  onClose,
  syncRequestError,
}) => {
  const errInfo = syncRequestError as SyncErrors.RemoveRetryChangedColumnTypes;
  const changedColumns = Array.isArray(errInfo?.changedColumns) ? errInfo.changedColumns : [];
  const maxColumns = 10;
  return (
    <Modal info isOpen={isOpen} title={"Column Types Changed in Model"} onClose={onClose}>
      <Container sx={{ maxWidth }}>
        <Row>
          <Text>
            Changing column types is not supported when there are removed rows that need to be retried. Try reverting your model
            and resolving any errors with removed rows before syncing your new query.
          </Text>
        </Row>
        {changedColumns.length > 0 && (
          <>
            <Row sx={{ mt: 2 }}>
              <Text>{`The affected ${oneOrMore("column is", "columns are", changedColumns.length)}:`}</Text>
            </Row>
            {changedColumns.slice(0, maxColumns).map((column) => {
              return (
                <Row key={column}>
                  <Text sx={{ fontFamily: "monospace" }}>{column}</Text>
                </Row>
              );
            })}
          </>
        )}
      </Container>
    </Modal>
  );
};

const SortRanOutOfDiskSpaceModal: FC<Readonly<SyncRequestErrorModalProps>> = ({ isOpen, onClose }) => {
  return (
    <Modal info isOpen={isOpen} title={"Sync too large"} onClose={onClose}>
      <Container sx={{ maxWidth }}>
        <Row>
          <Text>Please enable&nbsp;</Text>
          <Link newTab to={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-planning`}>
            Warehouse Planning
          </Link>
          <Text>&nbsp;to ensure that this sync can run.</Text>
        </Row>
      </Container>
    </Modal>
  );
};

const WarehouseTableMissingModal: FC<Readonly<SyncRequestErrorModalProps>> = ({ isOpen, onClose, syncRequestError }) => {
  const errInfo = syncRequestError as SyncErrors.WarehouseTableMissing;
  const missingTables = Array.isArray(errInfo?.missingTables) ? errInfo.missingTables : [];
  return (
    <Modal info isOpen={isOpen} title={"Required tables missing"} onClose={onClose}>
      <Container sx={{ maxWidth }}>
        <Row>
          <Text>Tables required for&nbsp;</Text>
          <Link newTab to={`${import.meta.env.VITE_DOCS_URL}/syncs/warehouse-planning`}>
            Warehouse Planning
          </Link>
          <Text>&nbsp;are not in your source.</Text>
        </Row>
        <Row sx={{ mt: 2 }}>
          <Text>{`Missing the following ${oneOrMore("table", "tables", missingTables.length)}:`}</Text>
        </Row>
        {missingTables.map((table) => {
          return (
            <Row key={table}>
              <Text sx={{ fontFamily: "monospace" }}>{table}</Text>
            </Row>
          );
        })}
      </Container>
    </Modal>
  );
};

// Default modal to use when we don't need a custom UI.
// Just displays any user-facing error saved on the error object.
const DefaultModal: FC<Readonly<SyncRequestErrorModalProps>> = ({ isOpen, onClose, syncRequestError }) => {
  return (
    <Modal info isOpen={isOpen} title="Run error" onClose={onClose}>
      <Text sx={{ maxWidth }}>{syncRequestError?.userFacingMessage || syncRequestError?.message}</Text>
    </Modal>
  );
};

export const SyncRequestErrorModal: FC<Readonly<SyncRequestErrorModalProps>> = ({
  isOpen,
  onClose,
  syncRequestError,
  syncStatus,
}) => {
  if (!syncRequestError) {
    return <></>;
  }

  const defaultProps = { isOpen, onClose, syncRequestError, syncStatus };

  let errorCode =
    syncRequestError.syncRequestErrorCode ||
    (syncStatus === SyncStatus.UNPROCESSABLE
      ? SyncRequestErrorCode.PREVIOUS_SYNC_RUN_OBJECT_MISSING
      : SyncRequestErrorCode.UNSPECIFIED);

  if (errorCode === SyncRequestErrorCode.UNSPECIFIED && syncRequestError.message) {
    try {
      const err: { type: string } = JSON.parse(syncRequestError.message);
      errorCode =
        err.type === "unmet_dependencies"
          ? SyncRequestErrorCode.PREVIOUS_SYNC_RUN_OBJECT_MISSING
          : SyncRequestErrorCode.UNSPECIFIED;
    } catch (err) {
      // message wasn't JSON
    }
  }

  switch (errorCode) {
    case SyncRequestErrorCode.NON_UNIQUE_PRIMARY_KEY:
      return <NonUniquePrimaryKeyModal {...defaultProps} />;

    case SyncRequestErrorCode.PREVIOUS_SYNC_RUN_OBJECT_MISSING:
      return <PreviousSyncRunObjectMissingModal {...defaultProps} />;

    case SyncRequestErrorCode.REMOVE_PLAN_INCOMPLETE:
      return <DefaultModal {...defaultProps} />;

    case SyncRequestErrorCode.REMOVE_RETRY_CHANGED_COLUMN_TYPES:
      return <RemoveRetryChangedColumnTypesModal {...defaultProps} />;

    case SyncRequestErrorCode.UNSPECIFIED:
      return <DefaultModal {...defaultProps} />;

    case SyncRequestErrorCode.SORT_RAN_OUT_OF_DISK_SPACE:
      return <SortRanOutOfDiskSpaceModal {...defaultProps} />;

    case SyncRequestErrorCode.WAREHOUSE_TABLE_MISSING:
      return <WarehouseTableMissingModal {...defaultProps} />;

    default:
      return <DefaultModal {...defaultProps} />;
  }
};
