import { FC, ReactNode } from "react";

import { usePermission } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import { ResourcePermission } from "src/graphql";

type Props = {
  permissions?: ResourcePermission[];
  fallback?: ReactNode;
};

export const Permission: FC<Readonly<Props>> = ({ children, permissions, fallback = null }) => {
  const { hasPermissions } = useUser();

  const permission = usePermission();

  const unauthorized = permissions ? !hasPermissions(permissions) : permission?.unauthorized;

  return <>{unauthorized ? fallback : children}</>;
};
