import { FC, ReactNode } from "react";

import { WorkspaceValidation } from "src/components/layout/header/workspace-validation";

import { Breadcrumbs, Crumb } from "./breadcrumbs";
import { Header, Topbar } from "./header";
import { TrialBanner } from "./trial-banner";
import { UserMenu } from "./user-menu";
import { WorkspaceWarning } from "./workspace-warning";

export type Props = {
  crumbs?: Crumb[];
  outsideTopbar?: ReactNode;
};

export const PageHeader: FC<Readonly<Props>> = ({ children, crumbs, outsideTopbar }) => {
  return (
    <Header>
      <WorkspaceWarning />
      <WorkspaceValidation />
      <TrialBanner />
      <Topbar>
        {children || (
          <>
            {crumbs && <Breadcrumbs crumbs={crumbs} />}
            <UserMenu />
          </>
        )}
      </Topbar>
      {outsideTopbar}
    </Header>
  );
};
