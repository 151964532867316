import { FC } from "react";

import * as Yup from "yup";

import { Section } from "src/ui/section";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { DeleteField } from "../delete-field";
import { FromIdField } from "../from-id-field";
import { MappingsField } from "../mappings-field";
import { ModeField } from "../mode-field";

export const validation = Yup.object().shape({
  fromId: Yup.mixed().required(),
  mappings: COMMON_SCHEMAS.mappings,
  customMappings: COMMON_SCHEMAS.mappings,
});

const MODES = [{ label: "Upsert", value: "upsert" }];

export const MoengageForm: FC = () => {
  return (
    <>
      <ModeField options={MODES} value={"upsert"} />

      <Section>
        <FromIdField fieldName={`MoEngage User ID`} />
      </Section>

      <Section>
        <MappingsField />
      </Section>

      <Section>
        <MappingsField isCustom />
      </Section>

      <Section>
        <DeleteField modes={["clear"]} />
      </Section>
    </>
  );
};

export default {
  form: MoengageForm,
  validation,
};
